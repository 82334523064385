import { Dialog, Transition } from '@headlessui/react'
import { Dispatch, Fragment, useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { PrimaryButton, SecondaryButton } from '../ui/Button'

type PopupOnClickEvent = {
  event: React.MouseEvent<HTMLElement, MouseEvent>
  closePopup: () => void
  setSpinner: (value: boolean) => void
}
type PopupOnClickEventHander = (e: PopupOnClickEvent) => void

type Button = {
  text: string
  onClick: PopupOnClickEventHander
}

export type PopupTextType = {
  header: string | JSX.Element
  content: string | JSX.Element
  onClick: PopupOnClickEventHander
  onSecondBtnClick?: PopupOnClickEventHander
}

type Props = {
  isOpen: boolean
  setIsOpen: Dispatch<React.SetStateAction<boolean>>
  popupHeader: string | JSX.Element
  popupContent: string | JSX.Element
  firstBtn: Button
  secondBtn: Button
  hideBtns?: boolean
  hideHeader?: boolean
  initialRef?: React.RefObject<HTMLElement>
  allowOnclose?: boolean
}

const Popup = ({
  isOpen,
  popupContent,
  popupHeader,
  firstBtn,
  secondBtn,
  hideBtns,
  setIsOpen,
  hideHeader,
  initialRef,
  allowOnclose = true,
}: Props) => {
  // const closeModal = () => setIsOpen(false)

  const [firstBtnLoading, setFirstBtnLoading] = useState(false)
  const [secondBtnLoading, setSecondBtnLoading] = useState(false)

  const firstBtnOnClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    firstBtn.onClick({
      event,
      closePopup: () => setIsOpen(false),
      setSpinner: (value) => setFirstBtnLoading(value),
    })
  }

  const secondBtnOnClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    secondBtn.onClick({
      event,
      closePopup: () => setIsOpen(false),
      setSpinner: (value) => setSecondBtnLoading(value),
    })
  }

  useEffect(() => {
    if (!isOpen) {
      setFirstBtnLoading(false)
      setSecondBtnLoading(false)
    }
  }, [isOpen])

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={
            allowOnclose
              ? (secondBtnOnClick as unknown as () => void)
              : () => {
                  setIsOpen(false)
                }
          }
          initialFocus={initialRef}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={` max-w-md min-w-[300px] max-h-[480px]  transform overflow-hidden bg-white ${
                    hideHeader ? 'p-0 rounded-sm' : 'p-6 rounded-2xl'
                  } text-left align-middle shadow-xl transition-all flex flex-col`}
                >
                  <div className="flex justify-between overflow-hidden">
                    <div className="flex items-center overflow-hidden">
                      {!hideHeader && (
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-medium leading-6 text-gray-900"
                        >
                          {popupHeader}
                        </Dialog.Title>
                      )}
                    </div>
                    {!hideHeader && (
                      <div
                        className="flex items-center transition ease-in-out delay-100 hover:scale-110 cursor-pointer"
                        onClick={
                          allowOnclose
                            ? secondBtnOnClick
                            : () => {
                                setIsOpen(false)
                              }
                        }
                      >
                        <CloseIcon sx={{ fontSize: 20 }} />
                      </div>
                    )}
                  </div>
                  {!hideHeader && (
                    <hr className="h-px mt-1  bg-gray-100 border-0"></hr>
                  )}
                  <div
                    className={`${
                      hideHeader ? 'mt-0' : 'mt-2'
                    } overflow-y-auto`}
                  >
                    <div className="text-sm text-gray-500">{popupContent}</div>
                  </div>

                  {!hideBtns && (
                    <div className="mt-2 flex flex-row-reverse justify-start">
                      <PrimaryButton
                        spinner={firstBtnLoading}
                        onClick={firstBtnOnClick}
                      >
                        {firstBtn.text}
                      </PrimaryButton>
                      <SecondaryButton
                        onClick={secondBtnOnClick}
                        spinner={secondBtnLoading}
                      >
                        {secondBtn.text}
                      </SecondaryButton>
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default Popup
