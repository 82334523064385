import { Deque } from 'js-sdsl'
import {
  DynamoFile,
  FileStructure,
  FolderItem,
  FolderStructure,
} from '../types'

export const buildFolderFromPaths = (paths: string[]) => {
  const root: any = {}
  paths.forEach((path) => {
    const parts = path.split('/').slice(1)
    let current = root
    parts.forEach((part, i) => {
      if (!current[part]) {
        current[part] = i === parts.length - 1 ? null : {}
      }
      current = current[part]
    })
  })
  return root
}

/**
 * @deprecated This function is deprecated in favor of getFilesFromFolder
 */
export const getFilesFromFolder = (
  folderStack: string[],
  folderStructure: FolderStructure,
  files: DynamoFile[]
): DynamoFile[] => {
  let current: FolderStructure | null = folderStructure
  for (let i = 0; i < folderStack.length; i++) {
    if (!current) {
      const tmp = folderStack.slice(0, i)
      const str = `/${tmp.join('/')}`
      return files.filter((file) => file.dir === `${str}/${file.fileName}`)
    }
    current = current[folderStack[i]]
  }
  const str = folderStack.length === 0 ? '' : `/${folderStack.join('/')}`
  return files.filter((file) => file.dir === `${str}/${file.fileName}`)
}

/**
 * @deprecated This function is deprecated in favor of getFoldersV2
 */
export const getFolders = (
  folderStack: string[],
  folderStructure: FolderStructure
): string[] => {
  let current: FolderStructure | null = folderStructure
  for (let i = 0; i < folderStack.length; i++) {
    if (!current) return []
    current = current[folderStack[i]]
  }
  if (current) {
    const keys = Object.keys(current)
    return keys.filter((key) => current![key] !== null)
  }
  return []
}

export const getFolderFilesV2 = (
  parentFolderId: string | undefined,
  files: DynamoFile[]
): DynamoFile[] => {
  return files.filter(
    (file) => file.type !== 'FOLDER' && file.parentFolderId === parentFolderId
  )
}

export const getFoldersV2 = (
  parentFolderId: string | undefined,
  files: DynamoFile[]
): FolderItem[] => {
  return files
    .filter(
      (file) => file.type === 'FOLDER' && file.parentFolderId === parentFolderId
    )
    .map(({ fileId, folderName, parentFolderId }) => ({
      id: fileId,
      name: folderName,
      parentFolderId,
    }))
}

export const getAllPossiblePathsV2 = (
  files: DynamoFile[]
): { id: string; path: string }[] => {
  const folders = files
    .filter((file) => file.type === 'FOLDER')
    .map(({ fileId, folderName, parentFolderId }) => ({
      id: fileId,
      name: folderName,
      parentFolderId,
    }))

  const rootFolders = folders.filter((folder) => !folder.parentFolderId)
  const nonRootFolders = folders.filter((folder) => folder.parentFolderId)

  const paths = [{ id: '', path: '/' }]

  paths.push(
    ...rootFolders.map((folder) => ({
      id: folder.id,
      path: `/${folder.name}`,
    }))
  )

  // find all nested folders and add them to paths
  for (const folder of rootFolders) {
    const nestedFolders = nonRootFolders.filter(
      (f) => f.parentFolderId === folder.id
    )
    for (const nestedFolder of nestedFolders) {
      paths.push({
        id: nestedFolder.id,
        path: `/${folder.name}/${nestedFolder.name}`,
      })
    }
  }

  return paths
}

const getAllPossiblePathsHelper = (
  paths: string[],
  prefix: string,
  folderStructure: FolderStructure | null
) => {
  if (!folderStructure) return
  for (const key of Object.keys(folderStructure)) {
    if (!folderStructure[key]) continue
    const p = prefix === '/' ? `${prefix}${key}` : `${prefix}/${key}`
    paths.push(p)
    getAllPossiblePathsHelper(paths, p, folderStructure[key])
  }
}

export const getAllPossiblePaths = (
  folderStructure: FolderStructure | null
) => {
  const path = ['/']
  getAllPossiblePathsHelper(path, '/', folderStructure)
  return path
}

export const getPath = (folders: FolderItem[]): string => {
  if (!folders.length) return '/'
  let path = ''
  for (const folder of folders) {
    path += `/${folder.id}`
  }
  return path
}

export const findPath = (
  node: FileStructure,
  target: string,
  path: string[]
) => {
  if (!node) return false
  for (const key in node) {
    path.push(key)
    if (key === target) return true
    if (node[key] && findPath(node[key] as FileStructure, target, path))
      return true
    path.pop()
  }

  return false
}

export const findLeastCommonAncestor = (
  root: FileStructure,
  nodes: string[]
) => {
  if (nodes.length === 0) return null

  const findLCAForTwo = (node1: string, node2: string): string | null => {
    const path1: string[] = []
    const path2: string[] = []

    if (!findPath(root, node1, path1) || !findPath(root, node2, path2)) {
      return null
    }

    let i = 0
    while (i < path1.length && i < path2.length && path1[i] === path2[i]) {
      i++
    }
    return path1[i - 1]
  }

  let currentLCA: string | null = nodes[0]

  for (let i = 1; i < nodes.length; i++) {
    currentLCA = findLCAForTwo(currentLCA!, nodes[i])
    if (!currentLCA) {
      return null
    }
  }

  return currentLCA
}

export const getAllFilesInAFolder = (folderId: string, files: DynamoFile[]) => {
  const queue = new Deque([folderId])
  const filterdFiles: DynamoFile[] = []

  while (queue.length > 0) {
    const id = queue.popFront()!
    const filter = files.filter(
      (file) => file.type === 'FILE' && file.parentFolderId === id
    )
    const filterFolder = files.filter(
      (file) => file.type === 'FOLDER' && file.parentFolderId === id
    )
    filter.forEach((file) => filterdFiles.push(file))
    filterFolder.forEach((folder) => queue.pushBack(folder.fileId))
  }

  return filterdFiles
}

export const getAllFileIdsInAFolder = (
  folderId: string,
  files: DynamoFile[]
) => {
  return getAllFilesInAFolder(folderId, files).map((file) => file.fileId)
}