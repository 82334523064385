import { useNavigate } from 'react-router-dom'
import { GENERAL_ERR_CONTENT } from '../../constant'
import ErrorMsg from '../ErrorMsg'
import FilesTree from '../FilesTree'
import { BasicSkeleton } from '../Skeleton'
import { EPlusFolder, FileStructure, TestFileCheck } from '../../types'
import { Dispatch } from 'react'
import SearchMode from './SearchMode'

type FilesElemProps = {
  navigate: ReturnType<typeof useNavigate>
  isLoading: boolean
  isFetching: boolean
  isError: boolean
  renderErrorTitle: () => string
  files: TestFileCheck[]
  onCheckAllFilesChange: () => void
  fileStructure: FileStructure
  checkAllFiles: boolean
  folders: EPlusFolder[]
  setFiles: Dispatch<React.SetStateAction<TestFileCheck[]>>
  refetch: () => Promise<any>
  allowStepThree: () => void
  setStepper: Dispatch<React.SetStateAction<0 | 1 | 2 | -1>>
  setInputRangeFocus: Dispatch<React.SetStateAction<boolean>>
  onSingleFileCheck: (id: string) => () => void
  setSelectedFolders: Dispatch<React.SetStateAction<string[]>>
  selectedFolders: string[]
  setSearchMode: Dispatch<React.SetStateAction<0 | 1 | 2>>
  searchMode: 0 | 1 | 2
  uncheckAllFiles: () => void
  foldersAreAllEmpty: boolean
}

const FilesElem = ({
  navigate,
  isError,
  isFetching,
  isLoading,
  renderErrorTitle,
  files,
  checkAllFiles,
  onCheckAllFilesChange,
  fileStructure,
  folders,
  setFiles,
  refetch,
  allowStepThree,
  setStepper,
  setInputRangeFocus,
  onSingleFileCheck,
  setSelectedFolders,
  selectedFolders,
  searchMode,
  setSearchMode,
  uncheckAllFiles,
  foldersAreAllEmpty
}: FilesElemProps) => {
  if (searchMode === 0)
    return (
      <SearchMode
        setInputRangeFocus={setInputRangeFocus}
        setStepper={setStepper}
        setSearchMode={setSearchMode}
      />
    )
  return (
    <>
      {(isLoading || isFetching) && (
        <div className="w-full h-full p-2">
          <BasicSkeleton />
        </div>
      )}
      {isError && (
        <div className="flex flex-col p-4">
          <ErrorMsg
            title={renderErrorTitle()}
            content={GENERAL_ERR_CONTENT}
            navigate={navigate}
          />
        </div>
      )}
      {!isError && !isLoading && !isFetching && (
        <div className="bg-white py-1 rounded max-h-80 h-fit">
          <div className="flex flex-col max-h-64 overflow-y-auto">
            <div className="flex justify-between items-center ">
              {searchMode === 1 && files.length > 0 && (
                <div className="flex items-center p-2">
                  <input
                    type="checkbox"
                    name="select-all"
                    id=""
                    className="mr-2 accent-[#107C41] border-[#616161] w-4 h-4"
                    checked={checkAllFiles}
                    onChange={onCheckAllFilesChange}
                  />
                  <label htmlFor="select-all">Select all</label>
                </div>
              )}
              {searchMode === 1 && !files.length && <p>No processed files identified</p>}
              {searchMode === 2 && foldersAreAllEmpty && <p>No processed files identified in folder(s)</p>}
            </div>

            {!(searchMode === 2 && foldersAreAllEmpty) && !(searchMode === 1 && !files.length) && <FilesTree
              fileStructure={fileStructure}
              files={files}
              folders={folders}
              setFiles={setFiles}
              selectedFolders={selectedFolders}
              setSelectedFolders={setSelectedFolders}
              searchMode={searchMode}
            />}

            {/* {searchMode === 1 &&
              files.map((file) => (
                <div
                  className="flex justify-between items-center"
                  key={file.id}
                >
                  <div className="flex items-center p-2">
                    <input
                      type="checkbox"
                      name="select-all"
                      id=""
                      className="mr-2 accent-[#107C41] border-[#616161] w-4 h-4"
                      checked={file.checked}
                      onChange={onSingleFileCheck(file.id)}
                    />
                    <label htmlFor="select-all">{file.filename}</label>
                  </div>
                </div>
              ))} */}
          </div>

          <div className="flex justify-between items-center">
            <div className="flex mt-4 h-fit">
              <button
                type="button"
                className="min-w-16 inline-flex text-white m-1 justify-center rounded-full px-2 py-1 sm:px-4 sm:py-2 text-xs sm:text-sm font-medium bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300"
                onClick={() => refetch()}
              >
                {'Refresh'}
              </button>
            </div>
            <div className="mt-4 flex flex-row-reverse justify-start h-fit">
              <button
                type="button"
                className="min-w-16 inline-flex text-white m-1 justify-center rounded-full px-2 py-1 sm:px-4 sm:py-2 text-xs sm:text-sm font-medium bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br"
                onClick={allowStepThree}
              >
                {'Next'}
              </button>
              <button
                type="button"
                className="min-w-16  inline-flex m-1 text-teal-600 justify-center rounded-full border border-teal-500 px-2 py-1 sm:px-4 sm:py-2 text-xs sm:text-sm font-medium hover:bg-gray-100"
                onClick={() => {
                  // setStepper(0)
                  // setInputRangeFocus(true)
                  setSearchMode(0)
                  uncheckAllFiles()
                  setSelectedFolders([])
                }}
              >
                {'Prev'}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default FilesElem
