import Dexie, { EntityTable } from 'dexie'
import { ExtractlyPlusFile } from '../types'

const db = new Dexie('ExtractlyPlus') as Dexie & {
  files: EntityTable<ExtractlyPlusFile, 'id'>
}

db.version(1).stores({ files: 'id' })

db.version(2)
  .stores({ files: 'id, createdAt' })
  .upgrade(async (tx) => {
    const files = await tx.table('files').toArray()
    for (const file of files) {
      if (!file.createdAt) {
        file.createdAt = new Date().toISOString()
      }
    }
    await tx.table('files').bulkPut(files)
  })

export { db }
