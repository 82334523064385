import { every, forEach, map, maxBy } from 'lodash'
import {
  BlockType,
  CellBlock,
  Cut,
  DataMatchOutput,
  DataMatchRowOutputs,
  DataMatchStatus,
  TestDataOcr,
  TestFile,
  TextBlock,
  TextcutRectangle,
} from '../types'
import { isCell } from './guards'
import { getFileV2 } from '../api'
import {
  calculateHeightandWidth,
  findTOpLeftPointFromVertices,
  rotateOcrBox,
} from './spatial'
import { fetchPDFFromLocal } from '../workbook'
import { PriorityQueue } from 'js-sdsl'
import numeral from 'numeral'
import { convertNumberToExcelDate, extractAndParseDate } from './date-util'
import currency from 'currency.js'
import { buildSearchIndex, buildNumberAndDateMap } from './elastic-lunr'
import { removeSpaces } from './data-match-utils'
import { containsCurrencySymbol } from './currency'

/**
 * OCR util function to break down the data
 * @param ocr
 * @returns pages, tables, cells, lines, words
 */
export const breakDownOcr = (
  ocr: TestDataOcr | undefined,
  degree: number
): [
  Map<string, TextBlock>,
  Map<string, TextBlock>,
  Map<string, TextBlock>,
  Map<string, TextBlock>,
  Map<string, TextBlock>
] => {
  const pages: TextBlock[] = []
  const pageMap = new Map<string, TextBlock>()
  const wordMap = new Map<string, TextBlock>()
  const lineMap = new Map<string, TextBlock>()
  const tableMap = new Map<string, TextBlock>()
  const cellMap = new Map<string, TextBlock>()
  if (!ocr) return [pageMap, tableMap, cellMap, lineMap, wordMap]
  // const copy = cloneDeep(ocr)
  const copy = deepCopyOcr(ocr) // performance improvement over cloneDeep
  for (const arr of copy) {
    const page = arr.textAnnotations.find(
      (block) => block.blockType === BlockType.PAGE
    )
    if (!page) continue
    const [topLeft, topRight, bottomRight, bottomLeft] =
      page.boundingPoly.vertices
    const [h, w] = calculateHeightandWidth(
      topLeft,
      topRight,
      bottomRight,
      bottomLeft
    )
    arr.textAnnotations.forEach((block) => {
      if (block.blockType !== BlockType.PAGE) {
        const newVertices = rotateOcrBox(
          block.boundingPoly.vertices,
          degree,
          w,
          h
        )
        block.boundingPoly.vertices = newVertices
      }
    })
  }
  forEach(copy, (item) => {
    forEach(item.textAnnotations, (block) => {
      if (block.blockType === BlockType.PAGE) pages.push(block)
      else if (block.blockType === BlockType.WORD) wordMap.set(block.id, block)
      else if (block.blockType === BlockType.LINE) lineMap.set(block.id, block)
      else if (block.blockType === BlockType.TABLE)
        tableMap.set(block.id, block)
      else if (block.blockType === BlockType.CELL) cellMap.set(block.id, block)
    })
  })

  forEach(pages, (page, i) => pageMap.set(String(i + 1), page))

  return [pageMap, tableMap, cellMap, lineMap, wordMap]
}

export const breakDownOcrByPage = (
  ocr: TestDataOcr | undefined,
  degree: number,
  currentPage: number
) => {
  const pageMap = new Map<string, TextBlock>()
  const wordMap = new Map<string, TextBlock>()
  const lineMap = new Map<string, TextBlock>()
  const tableMap = new Map<string, TextBlock>()
  const cellMap = new Map<string, TextBlock>()
  if (!ocr || !ocr.at(currentPage))
    return [pageMap, tableMap, cellMap, lineMap, wordMap]

  const page = ocr
    .at(currentPage)!
    .textAnnotations.find((block) => block.blockType === BlockType.PAGE)
  if (!page) return [pageMap, tableMap, cellMap, lineMap, wordMap]
  const [topLeft, topRight, bottomRight, bottomLeft] =
    page.boundingPoly.vertices
  const [h, w] = calculateHeightandWidth(
    topLeft,
    topRight,
    bottomRight,
    bottomLeft
  )
  ocr.at(currentPage)!.textAnnotations.forEach((block) => {
    if (block.blockType !== BlockType.PAGE) {
      const newVertices = rotateOcrBox(
        block.boundingPoly.vertices,
        degree,
        w,
        h
      )
      block.boundingPoly.vertices = newVertices
    }
  })

  forEach(ocr.at(currentPage)!.textAnnotations, (block) => {
    if (block.blockType === BlockType.PAGE)
      pageMap.set(currentPage + 1 + '', block)
    else if (block.blockType === BlockType.WORD) wordMap.set(block.id, block)
    else if (block.blockType === BlockType.LINE) lineMap.set(block.id, block)
    else if (block.blockType === BlockType.TABLE) tableMap.set(block.id, block)
    else if (block.blockType === BlockType.CELL) cellMap.set(block.id, block)
  })

  return [pageMap, tableMap, cellMap, lineMap, wordMap]
}

export const fillterDataBasedOnCutType = (
  page: TextBlock | undefined,
  tables: Map<string, TextBlock>,
  cells: Map<string, TextBlock>,
  lines: Map<string, TextBlock>,
  words: Map<string, TextBlock>,
  type: Cut
) => {
  const rects: TextBlock[] = []
  if (!page) return rects

  // if (type === Cut.TABLECUT || type === Cut.TABLES) {
  forEach(page.relationships, (re) => {
    forEach(re.ids, (id) => {
      if (tables.has(id)) {
        const table = tables.get(id)
        if (table) {
          rects.push(table)
          forEach(table.relationships, (re) => {
            forEach(re.ids, (id) => {
              const cell = cells.get(id)
              if (cell) {
                rects.push(cell)
              }
            })
          })
        }
      }
    })
  })
  // } else if (type === Cut.TEXTCUT || type === Cut.WORD || type === Cut.SUM) {
  forEach(page.relationships, (re) => [
    forEach(re.ids, (id) => {
      if (lines.has(id)) {
        const line = lines.get(id) as TextBlock
        forEach(line.relationships, (re) => {
          forEach(re.ids, (id) => {
            rects.push(words.get(id) as TextBlock)
          })
        })
      }
    }),
  ])
  // } else if (type === Cut.LINE) {
  //   forEach(page.relationships, (re) => {
  //     forEach(re.ids, (id) => {
  //       if (lines.has(id)) {
  //         const line = lines.get(id)
  //         if (line) rects.push(line)
  //       }
  //     })
  //   })
  // }
  return rects
}

/**
 * Transform text block to cell block
 * @param cells
 * @param words
 * @returns
 */
export const transformCells = (
  cells: TextBlock[],
  words: Map<string, TextBlock>
): CellBlock[] => {
  const bool = every(cells, (cell) => isCell(cell))
  if (!bool) return []
  const newCells = map(cells, (cell) => {
    const wordIds = cell?.relationships ? cell.relationships[0].ids : []
    const wordArray = map(wordIds, (id) => {
      const word = words.get(id)
      return word ? word.description : ''
    })
    const newCell = {
      ...cell,
      text: wordArray.length ? wordArray.join(' ') : '',
    }
    return newCell
  })
  return newCells as CellBlock[]
}

export const findRowAndColFromCells = (
  cells: CellBlock[]
): [row: number, col: number] => {
  const row = maxBy(cells, (cell) => cell.rowIndex)
  const col = maxBy(cells, (cell) => cell.columnIndex)
  return [row ? row.rowIndex : -1, col ? col.columnIndex : -1]
}

export const searchInputInFiles = async (
  files: TestFile[],
  input: any[][],
  primaryColumnIndices: number[],
  firstRowHeader: boolean,
  isLocalMode: boolean
): Promise<[any[][], number]> => {
  let hasSearched = 0
  const result: any[][] = []
  for (let i = 0; i < input.length; i++) {
    const tmp: any[] = []
    for (let j = 0; j < input[i].length; j++) {
      tmp.push(undefined)
      input[i][j] = String(input[i][j]).trim()
    }
    result.push(tmp)
  }
  if (firstRowHeader && input.length === 1) return [result, hasSearched]

  if (firstRowHeader) {
    const firstRow = [...input[0]]
    result[0] = firstRow
  }

  for (const file of files) {
    hasSearched++
    let ocr: TestDataOcr
    if (isLocalMode) {
      const f = await fetchPDFFromLocal(file.id)
      ocr = f?.ocr ?? []
    } else {
      const f = await getFileV2(file.id)
      ocr = f.ocr
    }

    const lines = ocr.map((r) => {
      const pageInfo = r.textAnnotations[0]
      const vs = pageInfo.boundingPoly.vertices
      const [h, w] = calculateHeightandWidth(vs[0], vs[1], vs[2], vs[3])
      return r.textAnnotations
        .filter((annotation) => annotation.blockType === BlockType.LINE)
        .map((block) => ({ ...block, ocrH: h, ocrW: w }))
    })
    const idx = firstRowHeader ? 1 : 0
    for (let i = idx; i < input.length; i++) {
      const resultRow = result[i]
      if (every(resultRow)) continue
      const searchValues = [...input[i]]
      const arr = searchInputFileHelper(searchValues, lines, file.id)
      // if (every(arr)) result[i] = arr
      if (
        resultRow.length === primaryColumnIndices.length ||
        primaryColumnIndices.length === 0
      ) {
        if (every(arr)) result[i] = arr
      } else {
        const middleMan: any[] = []
        primaryColumnIndices.forEach((index) => middleMan.push(arr[index]))
        if (every(middleMan)) result[i] = arr
      }
    }
  }
  return [result, hasSearched]
}

const searchInputFileHelper = (
  valueToSearch: any[],
  ocrWOrds: TextBlock[][],
  fileId: string
) => {
  const result = Array.from(valueToSearch).fill(undefined)
  for (let i = 0; i < result.length; i++) {
    for (let j = 0; j < ocrWOrds.length; j++) {
      for (let k = 0; k < ocrWOrds[j].length; k++) {
        if (
          ocrWOrds[j][k].description
            .toLocaleLowerCase()
            .includes(valueToSearch[i].toLocaleLowerCase())
        ) {
          // add file id and file page to the textblock
          result[i] = { ...ocrWOrds[j][k], fileId, filePage: j + 1 }
        }
        if (every(result)) return result
      }
    }
  }
  return result
}

export const pickUpTableCells = (
  tables: TextBlock[],
  cells: Map<string, TextBlock>,
  type: Cut
) => {
  if (type !== Cut.TABLECUT && type !== Cut.TABLES) return []
  const rects: TextBlock[] = []
  tables.forEach((table) => {
    const tableRelationship = table?.relationships?.at(0)
    if (tableRelationship) {
      const cellIds = tableRelationship.ids
      cellIds.forEach((id) => {
        const cell = cells.get(id)
        if (cell) {
          rects.push(cell)
        }
      })
    }
  })
  return rects
}

export const pickUpCellsFromTableReferences = (
  refs: TextcutRectangle[],
  tables: Map<string, TextBlock>,
  cells: Map<string, TextBlock>,
  fileId: string,
  filePage: number,
  showSelectionOnly: boolean,
  sheetId: string,
  rangeAddr: string
) => {
  const rects: TextBlock[] = []
  const ocrIds: string[] = []
  let filteredRefs = refs.filter(
    (ref) => ref.fileId === fileId && ref.filePage === filePage
  )

  if (showSelectionOnly)
    filteredRefs = filteredRefs.filter(
      (ref) => ref.sheetId === sheetId && ref.rangeAddr === rangeAddr
    )
  for (const ref of filteredRefs) {
    if (ref.ocrId) ocrIds.push(ref.ocrId)
  }
  const ocrIdSet = new Set(ocrIds)
  for (const tableId of ocrIdSet) {
    const table = tables.get(tableId)
    if (table) {
      const tableRelationship = table?.relationships?.at(0)
      if (tableRelationship) {
        const cellIds = tableRelationship.ids
        cellIds.forEach((id) => {
          const cell = cells.get(id)
          if (cell) {
            rects.push(cell)
          }
        })
      }
    }
  }
  return rects
}

export const createOutputPQ = (len: number): PriorityQueue<any[]>[] => {
  if (len < 0) throw new RangeError('Length cannot be negative')
  const arr = []
  for (let i = 0; i < len; i++) {
    arr[i] = new PriorityQueue<any[]>([], (a, b) => {
      const countA = a.reduce((acc, curr) => {
        if (curr === null) return acc + 1
        return acc
      }, 0)

      const countB = b.reduce((acc, curr) => {
        if (curr === null) return acc + 1
        return acc
      }, 0)

      return countA - countB
    })
  }
  return arr
}

export const previewOcr = (ocr: TestDataOcr) => {
  return ocr.reduce((a, b) => a + b.textPreview, '')
}

export const preProcessInput = (input: any[][], inputValues: any[][]) => {
  return input.map((row, r) =>
    row.map((val, c) => {
      const text = String(val).toLocaleLowerCase()
      const date = extractAndParseDate(text)
      if (date) {
        return convertNumberToExcelDate(inputValues[r][c])
      }
      const num = currency(text)
      if (!isNaN(num.value) && num.value !== 0) {
        return num.value
      }
      return text
    })
  )
}

export const processOutputPQ = (output: PriorityQueue<any[]>[]) => {
  const result: DataMatchOutput = []
  for (const row of output) {
    const processedResult = processSingleOutputPQ(row)
    result.push(processedResult)
  }
  // return result.map((v, index) => (v[index] === undefined ? '#Not found' : v))
  return result
}

export const processSingleOutputPQ = (
  pq: PriorityQueue<any[]>
): DataMatchRowOutputs => {
  if (!pq.size()) throw new Error('Invalid output priority queue')
  const top = pq.pop()
  if (!top) throw new Error('Invalid output priority queue')
  const slicedTop = top.slice(0, -1)
  const accuracy = calculateDataMatchAccuracy(slicedTop)
  if (accuracy.value() === 0) {
    return [slicedTop.map((_) => DataMatchStatus.NOT_FOUND)]
  }
  if (pq.size() === 0) {
    return [[...slicedTop]]
  }
  // return [...slicedTop]
  const findings = [[...slicedTop]]
  while (true) {
    const nextTop = pq.pop()
    if (!nextTop) throw new Error('Invalid output priority queue')
    const nextSlicedTop = nextTop.slice(0, -1)
    const nextAccuracy = calculateDataMatchAccuracy(nextSlicedTop)
    if (accuracy.value() !== nextAccuracy.value()) break
    let exactDataMatch = true
    for (let i = 0; i < slicedTop.length; i++) {
      if (
        (slicedTop[i] == null && nextSlicedTop[i] != null) ||
        (slicedTop[i] != null && nextSlicedTop[i] == null)
      )
        exactDataMatch = false
      break
    }
    if (!exactDataMatch) break
    findings.push([...nextSlicedTop])
    if (!pq.size()) break
  }
  return findings
  // const nextTop = pq.pop()
  // if (!nextTop) throw new Error('Invalid output priority queue')
  // const nextSlicedTop = nextTop.slice(0, -1)
  // const nextAccuracy = calculateDataMatchAccuracy(nextSlicedTop)
  // if (accuracy.value() === nextAccuracy.value()) {
  //   let exactDataMatch = true
  //   for (let i = 0; i < slicedTop.length; i++) {
  //     if (
  //       (slicedTop[i] === undefined && nextSlicedTop[i] !== undefined) ||
  //       (slicedTop[i] !== undefined && nextSlicedTop[i] === undefined)
  //     )
  //       exactDataMatch = false
  //   }
  //   if (exactDataMatch) {
  //     return slicedTop.map((_: any) => DataMatchStatus.DUPLICATE)
  //   }
  //   return [...slicedTop]
  // }
  // return [...slicedTop]
}

export const calculateDataMatchAccuracy = (row: any[]) => {
  const result = row.reduce((a, b) => {
    return b === null ? a : a + 1
  }, 0)
  return numeral(result).divide(row.length)
}

export const dataMatch = (
  files: TestFile[],
  input: any[][],
  inputValues: any[][],
  primaryIndiecs: number[],
  firstRowHeader: 0 | 1
): DataMatchOutput => {
  const actualInput = firstRowHeader ? input.slice(1, input.length) : input
  const actualInputValues = firstRowHeader
    ? inputValues.slice(1, input.length)
    : inputValues
  const processedInput = preProcessInput(actualInput, actualInputValues)
  const output: PriorityQueue<any[]>[] = createOutputPQ(processedInput.length)
  for (const file of files) {
    const ocr = file.ocrJson
    const dateCache = file.dateCache ? file.dateCache : []

    const [pages, , , lines, words] = breakDownOcr(ocr, 0)

    const { dateMap, numberMap } = buildNumberAndDateMap(
      lines,
      words,
      dateCache
    )

    const searchIndex = buildSearchIndex(words, lines, dateCache)

    for (let j = 0; j < processedInput.length; j++) {
      const inputRow = processedInput[j]
      const outputQueue = output[j]
      const result: any[] = inputRow.map((_) => null)
      for (let i = 0; i < inputRow.length; i++) {
        const searchInput = String(inputRow[i])
        const rawInput = String(actualInputValues[j][i])
        if (searchInput === '') {
          result[i] = ''
          continue
        }
        const response = searchIndex.search(searchInput, {})
        if (!response.length) continue
        const mapped = response.map((re) => {
          if (words.has(re.ref)) return words.get(re.ref)!
          if (lines.has(re.ref)) return lines.get(re.ref)!
          return null
        })

        const filteredItem = mapped.find((item, i) => {
          if (!item) return false

          const normalizedDescription = removeSpaces(
            item.description
          ).toLocaleLowerCase()
          const normalizedSearchInput =
            removeSpaces(searchInput).toLocaleLowerCase()
          const normalizedRawInput = removeSpaces(rawInput).toLocaleLowerCase()
          if (
            normalizedSearchInput &&
            normalizedDescription.includes(normalizedSearchInput)
          ) {
            return true
          }
          if (
            normalizedRawInput &&
            normalizedDescription.includes(normalizedRawInput)
          ) {
            return true
          }

          if (dateMap.has(item.id) && dateMap.get(item.id) === searchInput) {
            return true
          }
          if (
            numberMap.has(item.id) &&
            containsCurrencySymbol(item.description) &&
            numberMap.get(item.id) === searchInput
          ) {
            return true
          }

          // if (response.at(i) && response[i].score > 0.8) {
          //   return true
          // }
          return false
        })

        if (filteredItem) {
          const [page, pageW, pageH] = getTextblockPage(
            pages,
            lines,
            filteredItem
          )
          result[i] = {
            ...filteredItem,
            fileId: file.id,
            filePage: page,
            pageH,
            pageW,
          }
        }
      }
      // for (const block of searchArray) {
      //   for (let i = 0; i < result.length; i++) {
      //     if (typeof result[i] !== 'undefined') continue
      //     let bool = false
      //     if (block.formmatedDescription.includes(inputRow[i])) bool = true
      //     if (!bool) {
      //       const fuzzyVal = fuzzyInputValue(inputRow[i])
      //       const fuzzyDescription = fuzzyTextBlockValue(
      //         block.id,
      //         block.description,
      //         dateCache
      //       )
      //       if (fuzzyDescription.includes(fuzzyVal)) bool = true
      //     }
      //     if (bool) {
      //       const page = getTextblockPage(pages, lines, block)
      //       if (page >= 0) {
      //         result[i] = { ...block, fileId: file.id, filePage: page }
      //         break
      //       }
      //     }
      //   }
      // }

      result.push(false)
      if (!primaryIndiecs.length) outputQueue.push(result)
      else {
        let passPrimaryIndicesCheck = true
        for (const index of primaryIndiecs) {
          if (result[index] === null) {
            passPrimaryIndicesCheck = false
            break
          }
        }
        if (passPrimaryIndicesCheck) outputQueue.push(result)
        else outputQueue.push(result.map((_) => null))
      }
    }
  }
  const outputResult = processOutputPQ(output)
  // const finalResult: DataMatchOutput = firstRowHeader
  //   ? [[...input[0]], ...outputResult]
  //   : outputResult
  if (firstRowHeader) {
    outputResult.unshift([[...input[0]]])
  }
  // console.log('outputResult:', outputResult)
  return outputResult
}

export const getTextblockPage = (
  pages: Map<string, TextBlock>,
  lines: Map<string, TextBlock>,
  block: TextBlock
) => {
  const page = -1
  for (const [k, v] of pages) {
    const pageW = v.boundingPoly.vertices[1].x - v.boundingPoly.vertices[0].x
    const pageH = v.boundingPoly.vertices[3].y - v.boundingPoly.vertices[0].y
    const children = v.relationships?.find((elem) => elem.type === 'CHILD')
    if (!children) continue
    for (const id of children.ids) {
      const line = lines.get(id)
      if (!line) continue
      if (line.id === block.id) return [Number(k), pageW, pageH]
      const lineChildren = line.relationships?.find(
        (elem) => elem.type === 'CHILD'
      )
      if (!lineChildren) continue
      if (lineChildren.ids.includes(block.id)) {
        return [Number(k), pageW, pageH]
      }
    }
  }

  return [page, 0, 0]
}

type SnipPossibleContinuousTablesArgs = {
  table: TextBlock
  filePage: number
  pages: Map<string, TextBlock>
  tables: Map<string, TextBlock>
  cells: Map<string, TextBlock>
  words: Map<string, TextBlock>
}

export const snipPossibleContinuousTables = (
  args: SnipPossibleContinuousTablesArgs
) => {
  const { table, cells, filePage, pages, tables, words } = args
  const headerTable = tableHasHeader({ table, cells })
  const tablesInPage = getAllTablesInPage(args)
  const tablesInPageLength = tablesInPage.length
  table.filePage = filePage
  if (!tablesInPageLength)
    throw new Error('Internal Error: snipPossibleContinuousTables')
  else if (tablesInPageLength === 1) {
    // TODO: Only table in the page, do a two way search
    if (headerTable) {
      const [prev, next] = twoWaySearchWithHeader({
        cells,
        filePage,
        pages,
        table,
        tables,
        words,
      })
      console.log([...prev, table, ...next])
      return [...prev, table, ...next]
    } else {
      const [prev, next] = twoWaySearchWithoutHeader({
        cells,
        filePage,
        pages,
        table,
        tables,
        words,
      })
      console.log([...prev, table, ...next])
      return [...prev, table, ...next]
    }
  } else {
    // TODO: if the table is the last table in the page, it must have a header to be considered as a continuous table
    if (tablesInPage[tablesInPageLength - 1].id === table.id) {
      if (!headerTable) return [table]
      else {
        // TODO: search next page
        const possibleTables: TextBlock[] = []
        searchNextPageTablesWithHeader({
          cells,
          pages,
          possibleTables,
          searchPage: filePage + 1,
          table,
          tables,
          words,
        })
        console.log([table, ...possibleTables])
        return [table, ...possibleTables]
      }
    } else if (tablesInPage[0].id !== table.id) {
      return [table]
    } else {
      // TODO: search prev page
      const possibleTables: TextBlock[] = []
      searchPrevPageTables({
        cells,
        pages,
        possibleTables,
        searchPage: filePage - 1,
        table,
        tables,
        words,
      })
      possibleTables.reverse()
      console.log([...possibleTables, table])
      return [...possibleTables, table]
    }
  }
}

type GetAllTablesInPageArgs = Omit<
  SnipPossibleContinuousTablesArgs,
  'table' | 'cells' | 'words'
>
const getAllTablesInPage = (args: GetAllTablesInPageArgs): TextBlock[] => {
  const { filePage, pages, tables } = args
  const page = pages.get(filePage + '')
  if (!page) return []
  const tablesInPage: TextBlock[] = []
  const pageChild = page.relationships?.find((re) => re.type === 'CHILD')
  pageChild?.ids.forEach((id) => {
    if (tables.has(id)) tablesInPage.push(tables.get(id)!)
  })
  return tablesInPage
}

type TableHasHeaderArgs = Pick<
  SnipPossibleContinuousTablesArgs,
  'table' | 'cells'
>
const tableHasHeader = ({ table, cells }: TableHasHeaderArgs) => {
  const tableChild = table.relationships?.find((re) => re.type === 'CHILD')
  if (!tableChild) return false
  return tableChild.ids.some((id) => {
    const cell = cells.get(id)
    return cell && cell.entityTypes?.includes('COLUMN_HEADER')
  })
}

type GetTableHeadersArgs = Pick<
  SnipPossibleContinuousTablesArgs,
  'table' | 'cells' | 'words'
>
const getTableHeaders = ({
  table,
  cells,
  words,
}: GetTableHeadersArgs): string | null => {
  const tableChild = table.relationships?.find((re) => re.type === 'CHILD')
  if (!tableChild) return null
  const headers = tableChild.ids
    .filter((id) => {
      if (!cells.has(id)) return false
      const cell = cells.get(id)!
      return cell.entityTypes?.includes('COLUMN_HEADER')
    })
    .map((id) => cells.get(id)!)
    .sort((a, b) => a.rowIndex! - b.rowIndex!)
  const headerStrings: string[] = []
  for (const header of headers) {
    const headerChild = header.relationships?.find((re) => re.type === 'CHILD')
    if (!headerChild) continue
    headerChild.ids.forEach((id) => {
      const word = words.get(id)
      if (word) headerStrings.push(word.description)
    })
  }
  return headerStrings.join('')
}

type SearchNextPageTablesArgs = Omit<
  SnipPossibleContinuousTablesArgs,
  'filePage'
> & { searchPage: number; possibleTables: TextBlock[] }
const searchNextPageTablesWithHeader = ({
  cells,
  pages,
  searchPage,
  tables,
  possibleTables,
  table,
  words,
}: SearchNextPageTablesArgs) => {
  const hasPage = pages.has(searchPage + '')
  if (!hasPage) return
  const tableHeader = getTableHeaders({ table, cells, words })
  const tableVertices = table.boundingPoly.vertices
  const [, width] = calculateHeightandWidth(
    tableVertices[0],
    tableVertices[1],
    tableVertices[2],
    tableVertices[3]
  )
  const topLeft = findTOpLeftPointFromVertices(tableVertices)
  if (!topLeft) return
  const tablesInPage = getAllTablesInPage({
    filePage: searchPage,
    pages,
    tables,
  })
  if (!tablesInPage.length) return
  else {
    const pageTable = tablesInPage[0]
    const pageInTableHeader = getTableHeaders({
      table: pageTable,
      cells,
      words,
    })
    const pageTableVertices = pageTable.boundingPoly.vertices
    const [, pageTableWidth] = calculateHeightandWidth(
      pageTableVertices[0],
      pageTableVertices[1],
      pageTableVertices[2],
      pageTableVertices[3]
    )
    const pageTableTopLeft = findTOpLeftPointFromVertices(pageTableVertices)
    if (!pageTableTopLeft) return
    if (pageInTableHeader) {
      if (pageInTableHeader === tableHeader) {
        pageTable.filePage = searchPage
        possibleTables.push(pageTable)
        if (tablesInPage.length === 1) {
          searchNextPageTablesWithHeader({
            cells,
            pages,
            possibleTables,
            searchPage: searchPage + 1,
            table: pageTable,
            tables,
            words,
          })
        }
      }
    } else {
      if (
        isDifferenceLessThan10Percent(width, pageTableWidth) &&
        isDifferenceLessThan10Percent(pageTableTopLeft.x, topLeft.x)
      ) {
        pageTable.filePage = searchPage
        possibleTables.push(pageTable)
        if (tablesInPage.length === 1) {
          searchNextPageTablesWithHeader({
            cells,
            pages,
            possibleTables,
            searchPage: searchPage + 1,
            table: pageTable,
            tables,
            words,
          })
        }
      }
    }
  }
}

const isDifferenceLessThan10Percent = (num1: number, num2: number) => {
  const difference = Math.abs(num1 - num2)
  const largerNumber = Math.max(num1, num2)
  const threshold = largerNumber / 10
  return difference < threshold
}

type TwoWaySearchWithoutHeaderArgs = SnipPossibleContinuousTablesArgs
const twoWaySearchWithoutHeader = ({
  cells,
  filePage,
  pages,
  table,
  tables,
  words,
}: TwoWaySearchWithoutHeaderArgs): [TextBlock[], TextBlock[]] => {
  const possiblePreTables: TextBlock[] = []
  const possibleNextTables: TextBlock[] = []

  twoWaySearchWithoutHeaderPrevTableHelper({
    cells,
    pages,
    possibleTables: possiblePreTables,
    searchPage: filePage - 1,
    table,
    tables,
    words,
  })

  twoWaySearchWithoutHeaderNextTableHelper({
    cells,
    pages,
    possibleTables: possibleNextTables,
    searchPage: filePage + 1,
    table,
    tables,
    words,
  })

  possiblePreTables.reverse()
  return [possiblePreTables, possibleNextTables]
}

type TwoWaySearchWithoutHeaderPrevTableHelperArgs = SearchNextPageTablesArgs
const twoWaySearchWithoutHeaderPrevTableHelper = ({
  cells,
  pages,
  possibleTables,
  searchPage,
  table,
  tables,
  words,
}: TwoWaySearchWithoutHeaderPrevTableHelperArgs) => {
  const hasPage = pages.has(searchPage + '')
  if (!hasPage) return
  const tableVertices = table.boundingPoly.vertices
  const [, width] = calculateHeightandWidth(
    tableVertices[0],
    tableVertices[1],
    tableVertices[2],
    tableVertices[3]
  )
  const topLeft = findTOpLeftPointFromVertices(tableVertices)
  if (!topLeft) return
  const tablesInPage = getAllTablesInPage({
    filePage: searchPage,
    pages,
    tables,
  })
  if (!tablesInPage.length) return
  else {
    const pageTable = tablesInPage[tablesInPage.length - 1]
    const pageTableHeader = getTableHeaders({
      table: pageTable,
      cells,
      words,
    })
    const pageTableVertices = pageTable.boundingPoly.vertices
    const [, pageTableWidth] = calculateHeightandWidth(
      pageTableVertices[0],
      pageTableVertices[1],
      pageTableVertices[2],
      pageTableVertices[3]
    )
    const pageTableTopLeft = findTOpLeftPointFromVertices(pageTableVertices)
    if (!pageTableTopLeft) return

    if (
      isDifferenceLessThan10Percent(width, pageTableWidth) &&
      isDifferenceLessThan10Percent(pageTableTopLeft.x, topLeft.x)
    ) {
      if (pageTableHeader) {
        pageTable.filePage = searchPage
        possibleTables.push(pageTable)
      } else {
        if (tablesInPage.length === 1) {
          pageTable.filePage = searchPage
          possibleTables.push(pageTable)
          twoWaySearchWithoutHeaderPrevTableHelper({
            cells,
            pages,
            possibleTables,
            searchPage: searchPage - 1,
            table: pageTable,
            tables,
            words,
          })
        }
      }
    }
  }
}

type TwoWaySearchWithoutHeaderNextTableHelper = SearchNextPageTablesArgs
const twoWaySearchWithoutHeaderNextTableHelper = ({
  cells,
  pages,
  possibleTables,
  searchPage,
  table,
  tables,
  words,
}: TwoWaySearchWithoutHeaderNextTableHelper) => {
  const hasPage = pages.has(searchPage + '')
  if (!hasPage) return
  const tableVertices = table.boundingPoly.vertices
  const [, width] = calculateHeightandWidth(
    tableVertices[0],
    tableVertices[1],
    tableVertices[2],
    tableVertices[3]
  )
  const topLeft = findTOpLeftPointFromVertices(tableVertices)
  if (!topLeft) return
  const tablesInPage = getAllTablesInPage({
    filePage: searchPage,
    pages,
    tables,
  })
  if (!tablesInPage.length) return
  else {
    const pageTable = tablesInPage[0]
    const pageTableHeader = getTableHeaders({
      table: pageTable,
      cells,
      words,
    })
    const pageTableVertices = pageTable.boundingPoly.vertices
    const [, pageTableWidth] = calculateHeightandWidth(
      pageTableVertices[0],
      pageTableVertices[1],
      pageTableVertices[2],
      pageTableVertices[3]
    )
    const pageTableTopLeft = findTOpLeftPointFromVertices(pageTableVertices)
    if (!pageTableTopLeft) return

    if (
      isDifferenceLessThan10Percent(width, pageTableWidth) &&
      isDifferenceLessThan10Percent(pageTableTopLeft.x, topLeft.x)
    ) {
      if (!pageTableHeader) {
        pageTable.filePage = searchPage
        possibleTables.push(pageTable)
        if (tablesInPage.length === 1) {
          twoWaySearchWithoutHeaderNextTableHelper({
            cells,
            pages,
            possibleTables,
            searchPage: searchPage + 1,
            table: pageTable,
            tables,
            words,
          })
        }
      }
    }
  }
}

type TwoWaySearchWithHeaderArgs = SnipPossibleContinuousTablesArgs
const twoWaySearchWithHeader = ({
  cells,
  filePage,
  pages,
  table,
  tables,
  words,
}: TwoWaySearchWithHeaderArgs): [TextBlock[], TextBlock[]] => {
  const possiblePreTables: TextBlock[] = []
  const possibleNextTables: TextBlock[] = []

  twoWaySearchWithHeaderPrevHelper({
    cells,
    pages,
    possibleTables: possiblePreTables,
    searchPage: filePage - 1,
    table,
    tables,
    words,
  })

  twoWaySearchWithHeaderNextHelper({
    cells,
    pages,
    possibleTables: possibleNextTables,
    searchPage: filePage + 1,
    table,
    tables,
    words,
  })

  possiblePreTables.reverse()

  return [possiblePreTables, possibleNextTables]
}

type TwoWaySearchWithHeaderPrevHelperArgs = SearchNextPageTablesArgs
const twoWaySearchWithHeaderPrevHelper = ({
  cells,
  pages,
  possibleTables,
  searchPage,
  table,
  tables,
  words,
}: TwoWaySearchWithHeaderPrevHelperArgs) => {
  const hasPage = pages.has(searchPage + '')
  if (!hasPage) return
  const tableHeader = getTableHeaders({ table, cells, words })
  const tableVertices = table.boundingPoly.vertices
  const [, width] = calculateHeightandWidth(
    tableVertices[0],
    tableVertices[1],
    tableVertices[2],
    tableVertices[3]
  )
  const topLeft = findTOpLeftPointFromVertices(tableVertices)
  if (!topLeft) return
  const tablesInPage = getAllTablesInPage({
    filePage: searchPage,
    pages,
    tables,
  })
  if (!tablesInPage.length) return
  else {
    const pageTable = tablesInPage[tablesInPage.length - 1]
    const pageTableHeader = getTableHeaders({
      table: pageTable,
      cells,
      words,
    })
    if (!pageTableHeader) return
    const pageTableVertices = pageTable.boundingPoly.vertices
    const [, pageTableWidth] = calculateHeightandWidth(
      pageTableVertices[0],
      pageTableVertices[1],
      pageTableVertices[2],
      pageTableVertices[3]
    )
    const pageTableTopLeft = findTOpLeftPointFromVertices(pageTableVertices)
    if (!pageTableTopLeft) return

    if (
      isDifferenceLessThan10Percent(width, pageTableWidth) &&
      isDifferenceLessThan10Percent(pageTableTopLeft.x, topLeft.x) &&
      tableHeader === pageTableHeader
    ) {
      pageTable.filePage = searchPage
      possibleTables.push(pageTable)

      if (tablesInPage.length === 1) {
        twoWaySearchWithHeaderPrevHelper({
          cells,
          pages,
          possibleTables,
          searchPage: searchPage - 1,
          table: pageTable,
          tables,
          words,
        })
      }
    }
  }
}

type TwoWaySearchWithHeaderNextHelperArgs = TwoWaySearchWithHeaderPrevHelperArgs
const twoWaySearchWithHeaderNextHelper = ({
  cells,
  pages,
  possibleTables,
  searchPage,
  table,
  tables,
  words,
}: TwoWaySearchWithHeaderNextHelperArgs) => {
  const hasPage = pages.has(searchPage + '')
  if (!hasPage) return
  // const tableHeader = getTableHeaders({ table, cells, words })
  const tableVertices = table.boundingPoly.vertices
  const [, width] = calculateHeightandWidth(
    tableVertices[0],
    tableVertices[1],
    tableVertices[2],
    tableVertices[3]
  )
  const topLeft = findTOpLeftPointFromVertices(tableVertices)
  if (!topLeft) return
  const tablesInPage = getAllTablesInPage({
    filePage: searchPage,
    pages,
    tables,
  })
  if (!tablesInPage.length) return
  else {
    const pageTable = tablesInPage[0]
    const pageTableVertices = pageTable.boundingPoly.vertices
    const [, pageTableWidth] = calculateHeightandWidth(
      pageTableVertices[0],
      pageTableVertices[1],
      pageTableVertices[2],
      pageTableVertices[3]
    )
    const pageTableTopLeft = findTOpLeftPointFromVertices(pageTableVertices)
    if (!pageTableTopLeft) return
    if (
      isDifferenceLessThan10Percent(width, pageTableWidth) &&
      isDifferenceLessThan10Percent(pageTableTopLeft.x, topLeft.x)
    ) {
      pageTable.filePage = searchPage
      possibleTables.push(pageTable)
      if (tablesInPage.length === 1) {
        twoWaySearchWithHeaderNextHelper({
          cells,
          pages,
          possibleTables,
          searchPage: searchPage + 1,
          table: pageTable,
          tables,
          words,
        })
      }
    }
  }
}

type SearchPrevPageTablesArgs = SearchNextPageTablesArgs
const searchPrevPageTables = ({
  cells,
  pages,
  possibleTables,
  searchPage,
  table,
  tables,
  words,
}: SearchPrevPageTablesArgs) => {
  const hasPage = pages.has(searchPage + '')
  if (!hasPage) return
  const tableHeader = getTableHeaders({ table, cells, words })
  const tableVertices = table.boundingPoly.vertices
  const [, width] = calculateHeightandWidth(
    tableVertices[0],
    tableVertices[1],
    tableVertices[2],
    tableVertices[3]
  )
  const topLeft = findTOpLeftPointFromVertices(tableVertices)
  if (!topLeft) return
  const tablesInPage = getAllTablesInPage({
    filePage: searchPage,
    pages,
    tables,
  })
  if (!tablesInPage.length) return
  else {
    const pageTable = tablesInPage[tablesInPage.length - 1]
    const pageTableHeader = getTableHeaders({
      table: pageTable,
      cells,
      words,
    })
    const pageTableVertices = pageTable.boundingPoly.vertices
    const [, pageTableWidth] = calculateHeightandWidth(
      pageTableVertices[0],
      pageTableVertices[1],
      pageTableVertices[2],
      pageTableVertices[3]
    )
    const pageTableTopLeft = findTOpLeftPointFromVertices(pageTableVertices)
    if (!pageTableTopLeft) return
    if (tableHeader) {
      if (
        isDifferenceLessThan10Percent(width, pageTableWidth) &&
        isDifferenceLessThan10Percent(pageTableTopLeft.x, topLeft.x) &&
        tableHeader === pageTableHeader
      ) {
        pageTable.filePage = searchPage
        possibleTables.push(pageTable)
        if (tablesInPage.length === 1) {
          searchPrevPageTables({
            cells,
            pages,
            possibleTables,
            searchPage: searchPage - 1,
            table: pageTable,
            tables,
            words,
          })
        }
      }
    } else {
      if (
        isDifferenceLessThan10Percent(width, pageTableWidth) &&
        isDifferenceLessThan10Percent(pageTableTopLeft.x, topLeft.x)
      ) {
        pageTable.filePage = searchPage
        possibleTables.push(pageTable)
        if (tablesInPage.length === 1 && !pageTableHeader) {
          searchPrevPageTables({
            cells,
            pages,
            possibleTables,
            searchPage: searchPage - 1,
            table: pageTable,
            tables,
            words,
          })
        }
      }
    }
  }
}

type TableCutPreprocessArgs = {
  table: TextBlock
  cells: Map<string, TextBlock>
  words: Map<string, TextBlock>
}
export const tableCutPreprocess = ({
  cells,
  table,
  words,
}: TableCutPreprocessArgs):
  | [string[][], string[][], number, number, number, TextBlock]
  | null => {
  const tableChildren = table.relationships?.find((re) => re.type === 'CHILD')
  if (!tableChildren) return null
  const tableCells = tableChildren.ids
    .filter((id) => cells.has(id))
    .map((id) => cells.get(id)!)
  const transformedTableCells = transformCells(tableCells, words)
  const [row, col] = findRowAndColFromCells(transformedTableCells)
  const matrix: string[][] = Array.from({ length: row }, () =>
    new Array(col).fill('')
  )
  const numberFormatMatrix: string[][] = Array.from({ length: row }, () =>
    new Array(col).fill('@')
  )
  forEach(
    transformedTableCells,
    (cell) =>
      (matrix[cell.rowIndex - 1][cell.columnIndex - 1] = cell.text
        ? cell.text
        : '')
  )
  return [matrix, numberFormatMatrix, row, col, table.filePage!, table]
}

export function deepCopyOcr(ocr: TestDataOcr): TestDataOcr {
  return ocr.map((arr) => ({
    ...arr,
    textAnnotations: arr.textAnnotations.map((block) => ({
      ...block,
      boundingPoly: {
        ...block.boundingPoly,
        vertices: block.boundingPoly.vertices.map((vertex) => ({ ...vertex })),
      },
    })),
  }))
}

export const getTablePage = (
  tableId: string,
  pages: Map<string, TextBlock>
): number => {
  for (const [k, v] of pages) {
    const p = v.relationships?.at(0)?.ids.find((id) => id === tableId)
    if (p) return Number(k)
  }
  return NaN
}
