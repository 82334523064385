import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { CutTag, TextcutRectangle } from '../types'

type ReferencesState = {
  references: TextcutRectangle[]
  isError: boolean
  error: string | undefined
  isLoading: boolean
}

const initialReferencesState: ReferencesState = {
  references: [],
  isError: false,
  error: undefined,
  isLoading: false,
}

export const initializeReferencesThunk = createAsyncThunk(
  'REFERENCES/initializeReferences',
  () =>
    Excel.run(async (ctx) => {
      const settings = ctx.workbook.settings
      const item = settings.getItemOrNullObject('REFERENCES')
      item.load(['isNullObject', 'value'])
      await ctx.sync()
      if (item.isNullObject) {
        const arr: TextcutRectangle[] = []
        settings.add('REFERENCES', JSON.stringify(arr))
        await ctx.sync()
        return arr
      }
      const elems: TextcutRectangle[] = JSON.parse(item.value)
      return elems
    })
)

export const addReferece = createAsyncThunk(
  'REFERENCES/addReference',
  (rect: TextcutRectangle) =>
    Excel.run(async (ctx) => {
      const settings = ctx.workbook.settings
      const item = settings.getItemOrNullObject('REFERENCES')
      item.load(['isNullObject', 'value'])
      await ctx.sync()
      let arr: TextcutRectangle[] = []
      if (item.isNullObject) {
        arr = [rect]
        settings.add('REFERENCES', JSON.stringify([rect]))
      } else {
        const elems: TextcutRectangle[] = JSON.parse(item.value)
        arr = elems.filter(
          (elem) =>
            (elem.tag === CutTag.SUM && elem.bindingGroupId) ||
            elem.sheetId !== rect.sheetId ||
            elem.rangeAddr !== rect.rangeAddr
        )
        arr = [...arr, rect]
      }
      settings.add('REFERENCES', JSON.stringify(arr))
      await ctx.sync()
      return arr
    })
)

export const removeReferece = createAsyncThunk(
  'REFERENCES/removeReference',
  (bindingId: string) => {
    return Excel.run(async (ctx) => {
      const custom = ctx.workbook.properties.custom
      const references = custom.getItemOrNullObject('REFERENCES')
      references.load(['key', 'value'])
      await ctx.sync()
      let arr: TextcutRectangle[] = []
      if (!references.isNullObject) {
        const value = references.value
        const rects: TextcutRectangle[] = JSON.parse(value)
        arr = rects.filter((rect) => rect.bindingId !== bindingId)
        custom.add('REFERENCES', JSON.stringify(arr))
      }
      await ctx.sync()
      return arr
    })
  }
)

export const resetReferences = createAsyncThunk(
  'REFERENCES/resetReferences',
  () =>
    Excel.run(async (ctx) => {
      const settings = ctx.workbook.settings
      settings.add('REFERENCES', JSON.stringify([]))
      await ctx.sync()
      return []
    })
)

export const addMultipleReferences = createAsyncThunk(
  'REFERENCES/addMultipleReferences',
  (rects: TextcutRectangle[]) =>
    Excel.run(async (ctx) => {
      const settings = ctx.workbook.settings
      const item = settings.getItemOrNullObject('REFERENCES')
      item.load(['isNullObject', 'value'])
      await ctx.sync()
      let arr: TextcutRectangle[] = []
      if (item.isNullObject) {
        arr = [...rects]
      } else {
        const elems: TextcutRectangle[] = JSON.parse(item.value)
        arr = elems.filter(
          (elem) =>
            !rects.some(
              (rect) =>
                rect.sheetId === elem.sheetId &&
                rect.rangeAddr === elem.rangeAddr
            )
        )
        arr = [...arr, ...rects]
      }
      settings.add('REFERENCES', JSON.stringify(arr))
      await ctx.sync()
      return arr
    })
)

export const deleteReference = createAsyncThunk(
  'REFERENCES/deleteReference',
  ({ sheetId, rangeAddress }: { sheetId: string; rangeAddress: string }) =>
    Excel.run(async (ctx) => {
      const settings = ctx.workbook.settings
      const item = settings.getItemOrNullObject('REFERENCES')

      const sheet = ctx.workbook.worksheets.getItemOrNullObject(sheetId)

      sheet.load(['isNullObject'])
      item.load(['isNullObject', 'value'])
      await ctx.sync()
      let arr: TextcutRectangle[] = []
      if (item.isNullObject) {
        arr = []
      } else {
        const elems: TextcutRectangle[] = JSON.parse(item.value)
        arr = elems.filter(
          (elem) => elem.sheetId !== sheetId || elem.rangeAddr !== rangeAddress
        )
      }
      settings.add('REFERENCES', JSON.stringify(arr))
      // if (!sheet.isNullObject && rangeAddress.trim() !== '') {
      //   const range = sheet.getRange(rangeAddress)
      //   range.clear()
      // }
      await ctx.sync()
      return arr
    })
)

export const deleteReferencesWithFileIds = createAsyncThunk(
  'REFERENCES/deleteReferencesWithFileId',
  (fileIds: string[]) =>
    Excel.run(async (ctx) => {
      const settings = ctx.workbook.settings
      const item = settings.getItemOrNullObject('REFERENCES')
      item.load(['isNullObject', 'value'])
      await ctx.sync()
      let arr: TextcutRectangle[] = []
      if (item.isNullObject) {
        arr = []
      } else {
        const elems: TextcutRectangle[] = JSON.parse(item.value)
        arr = elems.filter((elem) => !fileIds.includes(elem.fileId))
        settings.add('REFERENCES', JSON.stringify(arr))
      }
      await ctx.sync()
      return arr
    })
)

export const updateRefereces = createAsyncThunk(
  'REFERENCES/updateRefereces',
  (newRefs: TextcutRectangle[]) =>
    Excel.run(async (ctx) => {
      const settings = ctx.workbook.settings
      const item = settings.getItemOrNullObject('REFERENCES')
      item.load(['isNullObject', 'value'])
      await ctx.sync()
      let arr: TextcutRectangle[] = []
      if (item.isNullObject) {
        arr = [...newRefs]
      } else {
        const elems: TextcutRectangle[] = JSON.parse(item.value)
        const filtered = elems.filter(
          (elem) => !newRefs.some((ref) => ref.bindingId === elem.bindingId)
        )
        arr = [...filtered, ...newRefs]
      }
      settings.add('REFERENCES', JSON.stringify(arr))
      await ctx.sync()
      return arr
    })
)

export const deleteReferencesWithBindingId = createAsyncThunk(
  'REFERENCES/deleteReferencesWithBindingId',
  (bindingId: string) =>
    Excel.run(async (ctx) => {
      const settings = ctx.workbook.settings
      const item = settings.getItemOrNullObject('REFERENCES')
      const binding = ctx.workbook.bindings.getItemOrNullObject(bindingId)
      binding.load(['id', 'isNullObject'])
      item.load(['isNullObject', 'value'])
      await ctx.sync()
      let arr: TextcutRectangle[] = []
      if (!item.isNullObject) {
        const elems: TextcutRectangle[] = JSON.parse(item.value)
        const tmp = elems.filter((elem) => elem.bindingId !== bindingId)
        arr = [...tmp]
        settings.add('REFERENCES', JSON.stringify(arr))
      }
      if (!binding.isNullObject) binding.delete()
      await ctx.sync()
      return arr
    })
)

export const deleteReferencesAndClearContentWithBindingId = createAsyncThunk(
  'REFERENCES/deleteReferencesAndClearContentWithBindingId',
  (bindingId: string) =>
    Excel.run(async (ctx) => {
      const settings = ctx.workbook.settings
      const item = settings.getItemOrNullObject('REFERENCES')
      const binding = ctx.workbook.bindings.getItemOrNullObject(bindingId)
      binding.load(['id', 'isNullObject'])
      item.load(['isNullObject', 'value'])
      await ctx.sync()
      let arr: TextcutRectangle[] = []
      const toBeCleared: TextcutRectangle[] = []
      if (!item.isNullObject) {
        const elems: TextcutRectangle[] = JSON.parse(item.value)
        const tmp: TextcutRectangle[] = []
        elems.forEach((elem) => {
          if (elem.bindingId === bindingId) {
            toBeCleared.push(elem)
          } else {
            tmp.push(elem)
          }
        })
        arr = [...tmp]
        settings.add('REFERENCES', JSON.stringify(arr))
      }
      if (!binding.isNullObject) binding.delete()
      toBeCleared.forEach((elem) => {
        const sheet = ctx.workbook.worksheets.getItem(elem.sheetId)
        const range = sheet.getRange(elem.rangeAddr)
        range.clear()
      })
      await ctx.sync()
      return arr
    })
)

export const deleteReferencesWithBindingIds = createAsyncThunk(
  'REFERENCES/deleteReferencesWithBindingIds',
  (bindingIds: string[]) =>
    Excel.run(async (ctx) => {
      const settings = ctx.workbook.settings
      const item = settings.getItemOrNullObject('REFERENCES')
      item.load(['isNullObject', 'value'])
      await ctx.sync()
      let arr: TextcutRectangle[] = []
      if (!item.isNullObject) {
        const elems: TextcutRectangle[] = JSON.parse(item.value)
        const tmp = elems.filter((elem) => !bindingIds.includes(elem.bindingId))
        const toBeDelete = elems.filter((elem) => bindingIds.includes(elem.bindingId))
        const bindingGroupIdSet = new Set<string>()
        toBeDelete.forEach(elem => {
          if (elem.bindingGroupId) bindingGroupIdSet.add(elem.bindingGroupId)
        })
        arr = tmp.filter(elem => {
          if (!elem.bindingGroupId)
            return true
          if (bindingGroupIdSet.has(elem.bindingGroupId)) return false
          return true
        })
        settings.add('REFERENCES', JSON.stringify(arr))
      }
      await ctx.sync()
      return arr
    })
)

export const deleteReferencesWithBindingGroupId = createAsyncThunk(
  'REFERENCES/deleteReferencesWithBindingGroupId',
  (groupId: string) =>
    Excel.run(async (ctx) => {
      const settings = ctx.workbook.settings
      const item = settings.getItemOrNullObject('REFERENCES')
      item.load(['isNullObject', 'value'])
      await ctx.sync()
      if (item.isNullObject) return []
      const elems: TextcutRectangle[] = JSON.parse(item.value)
      const filtered = elems.filter((elem) => elem.bindingGroupId !== groupId)
      settings.add('REFERENCES', JSON.stringify(filtered))
      await ctx.sync()
      return filtered
    })
)

const referenceSlice = createSlice({
  name: 'REFERENCES',
  initialState: initialReferencesState,
  reducers: {},
  extraReducers(builder) {
    /**initializeReferencesThunk */
    builder.addCase(initializeReferencesThunk.fulfilled, (state, action) => {
      state.isLoading = false
      state.references = action.payload
    })
    builder.addCase(initializeReferencesThunk.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(initializeReferencesThunk.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.error = action.error.message
    })
    /**End of initializeReferencesThunk */

    /**addReferece */
    builder.addCase(addReferece.fulfilled, (state, action) => {
      state.isLoading = false
      state.references = action.payload
    })
    builder.addCase(addReferece.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(addReferece.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.error = action.error.message
    })
    /**End of addReference */

    // /**removeReference */
    // builder.addCase(removeReferece.fulfilled, (state, action) => {
    //   state.isLoading = false
    //   state.references = action.payload
    // })
    // builder.addCase(removeReferece.pending, (state) => {
    //   state.isLoading = true
    // })
    // builder.addCase(removeReferece.rejected, (state, action) => {
    //   state.isLoading = false
    //   state.isError = true
    //   state.error = action.error.message
    // })
    // /**End of removeReference */

    /**add multiple refereces */
    builder.addCase(addMultipleReferences.fulfilled, (state, action) => {
      state.isLoading = false
      state.references = action.payload
    })
    builder.addCase(addMultipleReferences.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(addMultipleReferences.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.error = action.error.message
    })
    /**End of add multiple references */

    /**deleteReference */
    builder.addCase(deleteReference.fulfilled, (state, action) => {
      state.isLoading = false
      state.references = action.payload
    })
    builder.addCase(deleteReference.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(deleteReference.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.error = action.error.message
    })
    /**End of deleteReference */

    builder.addCase(resetReferences.fulfilled, (state, action) => {
      state.references = action.payload
    })

    /** deleteReferencesWithFileIds */
    builder.addCase(deleteReferencesWithFileIds.fulfilled, (state, action) => {
      state.isLoading = false
      state.references = action.payload
    })
    builder.addCase(deleteReferencesWithFileIds.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(deleteReferencesWithFileIds.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.error = action.error.message
    })
    /** end of deleteReferencesWithFileIds */

    builder.addCase(updateRefereces.fulfilled, (state, action) => {
      state.references = action.payload
    })

    builder.addCase(
      deleteReferencesWithBindingId.fulfilled,
      (state, action) => {
        state.references = action.payload
      }
    )

    builder.addCase(
      deleteReferencesWithBindingIds.fulfilled,
      (state, action) => {
        state.references = action.payload
      }
    )

    builder.addCase(
      deleteReferencesWithBindingGroupId.fulfilled,
      (state, action) => {
        state.references = action.payload
      }
    )

    builder.addCase(
      deleteReferencesAndClearContentWithBindingId.fulfilled,
      (state, action) => {
        state.references = action.payload
      }
    )
  },
})

export default referenceSlice.reducer
