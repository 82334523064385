import { useNavigate } from 'react-router-dom'
import {
  createFolder,
  deleteFile,
  getFileV2,
  trimPages,
  updateFileName,
  updateParentFolder,
} from '../../api'
import {
  CutTag,
  Downloadable,
  DynamoFile,
  FileStatus,
  FolderItem,
  GetFileType,
  TestDataOcr,
  TextcutRectangle,
} from '../../types'
import Dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { useAppDispatch, useAppSelector } from '../../dispatch'
import { Select, Tab } from '@headlessui/react'
import { Dispatch, Fragment, useEffect, useRef, useState } from 'react'
import {
  deleteReferencesWithFileIds,
  resetReferences,
  updateRefereces,
} from '../../slice/referenceSlice'
import download from 'downloadjs'
import { Color, PDFArray, PDFDocument, PDFName, PDFString, rgb } from 'pdf-lib'
import { nanoid } from 'nanoid'
import {
  autoSave,
  fetchPDFFromLocal,
  removeAttachedFile,
  removeLocalFileRecordWithIds,
  replaceLocalPDF,
  resetLocalFileRecord,
  saveFileRecordsToLocal,
  savePdfsToWorkbook,
} from '../../workbook'
import useFiles from '../../hooks/useFiles'
import Popup, { PopupTextType } from '../Popup'
import FileUnorderedList from '../FileUnorderedList'
import { motion } from 'framer-motion'
import CustomRadio from '../CustomRadio'
import JSZip from 'jszip'
import { rotateARectangle, rotateAVertex } from '../../utils/spatial'
import dayjs from 'dayjs'
import { resetComments } from '../../slice/commentSlice'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import UploadContainer from '../UploadContainer'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import ErrorMsg from '../ErrorMsg'
import { GENERAL_ERR_CONTENT } from '../../constant'
import { isOfficeError } from '../../utils/guards'
import {
  getOfficeErrorTitle,
  getOfficeRuntimeAccessToken,
  fileSizeLessThan5MB,
} from '../../utils/common'
import {
  getAllPossiblePathsV2,
  getFolderFilesV2,
  getFoldersV2,
  getPath,
} from '../../utils/file_management'
import { MovePopupContent, MovePopupHeader } from '../MovePopup'
import { toast, ToastContainer } from 'react-toastify'
import { Input } from '../ui/Input'
import { last } from 'lodash'
import Skeleton, { SmallScreenSkeleton } from '../Skeleton'
import { getFileFromDB, removeFileFromDB } from '../../utils/db'
import 'react-toastify/dist/ReactToastify.css'
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined'
import MobileHeader from './MobileHeader'
import MobileFileList from './MobileFileList'
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined'
import MultiSelectionBar from './MultiSelectionBar'
import { useSet } from 'react-use'
import { LinkList } from 'js-sdsl'
import axios from 'axios'

Dayjs.extend(duration)
Dayjs.extend(require('dayjs/plugin/localizedFormat'))
const IS_DEV_MODE = process.env.REACT_APP_IS_DEV_MODE
console.log('IS_DEV_MODE', IS_DEV_MODE)
type FileCategory = 'ALL' | 'LINKED' | 'UNLINKED'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

enum Category {
  DEFAULT = 'All',
  ALL = 'All Files',
  LINKED = 'Linked',
  UNLINKED = 'Unlinked',
}

const generatePdfLibRgb = ([r, g, b]: [number, number, number]) =>
  rgb(r / 255, g / 255, b / 255)

const getColor = (
  tag: CutTag
): {
  color: Color | undefined
  opacity: number | undefined
  borderColor: Color
  borderWidth: 1 | 2
} => {
  if (tag === CutTag.DATA_MATCH) {
    return {
      // color: rgb(173 / 255, 216 / 255, 230 / 255),
      // opacity: 0.3,
      color: undefined,
      opacity: undefined,
      borderColor: generatePdfLibRgb([169, 224, 254]),
      borderWidth: 2,
    }
  } else if (tag === CutTag.TEXTCUT) {
    return {
      // color: rgb(255 / 255, 99 / 255, 71 / 255),
      // opacity: 0.3,
      color: undefined,
      opacity: undefined,
      borderColor: generatePdfLibRgb([182, 251, 251]),
      borderWidth: 2,
    }
  } else if (tag === CutTag.SUM) {
    return {
      // color: rgb(0 / 255, 191 / 255, 255 / 255),
      // opacity: 0.3,
      color: undefined,
      opacity: undefined,
      borderColor: generatePdfLibRgb([253, 230, 182]),
      borderWidth: 2,
    }
  } else if (tag === CutTag.REDACT) {
    return {
      color: rgb(0, 0, 0),
      opacity: 1,
      borderColor: rgb(0, 0, 0),
      borderWidth: 1,
    }
  } else {
    return {
      // color: rgb(247 / 255, 202 / 255, 201 / 255),
      // opacity: 0.5,
      color: undefined,
      opacity: undefined,
      borderColor: generatePdfLibRgb([182, 251, 251]),
      borderWidth: 2,
    }
  }
}

const TRANSFER = {
  header: 'Transfer',
  content:
    'Are you sure you want to transfer all files to this workbooks? All files saved in the cloud will be deleted after transfer.',
}

const downloadPDFFromCloud = async (
  file: DynamoFile
): Promise<Downloadable> => {
  const cachedFile = await getFileFromDB(file.fileId)
  if (cachedFile) {
    return {
      blob: cachedFile.pdf,
      fileName: `${cachedFile.fileName}`,
      type: 'application/pdf',
    }
  }
  const f = await getFileV2(file.fileId)
  return {
    blob: f.pdf,
    fileName: `${f.fileName}`,
    type: 'application/pdf',
  }
}

const downloadPDFFromLocal = async (
  file: DynamoFile
): Promise<Downloadable> => {
  const f = await getFileFromDB(file.fileId)
  if (f) {
    return {
      blob: f.pdf,
      fileName: `${f.fileName}`,
      type: 'application/pdf',
    }
  }
  const res = await fetchPDFFromLocal(file.fileId)
  const blob = res?.pdfBlob
  if (blob)
    return {
      blob,
      fileName: `${res.fileName}`,
      type: 'application/pdf',
    }
  else {
    throw new Error('Failed to download pdf from local')
  }
}

const exportPDFFromCloud = async (file: DynamoFile) => {
  const cachedFile = await getFileFromDB(file.fileId)
  if (cachedFile) return cachedFile.pdf
  const f = await getFileV2(file.fileId)
  return f.pdf
}

const exportPDFFromLocal = async (file: DynamoFile) => {
  const f = await getFileFromDB(file.fileId)
  if (f) return f.pdf
  const res = await fetchPDFFromLocal(file.fileId)
  return res?.pdfBlob
}

const convertPDFToPdfDocumentCloud = async (file: DynamoFile) => {
  const f = await getFileV2(file.fileId)
  // const result = await fetch(res.pdfUrl)
  const pdfBytes = await f.pdf.arrayBuffer()
  const pdfDoc = await PDFDocument.load(pdfBytes)
  return pdfDoc
}

const convertPDFToPdfDocumentLocal = async (file: DynamoFile) => {
  const res = await fetchPDFFromLocal(file.fileId)
  const blob = res?.pdfBlob
  if (blob) {
    const pdfBytes = await blob.arrayBuffer()
    const pdfDoc = await PDFDocument.load(pdfBytes)
    return pdfDoc
  } else {
    throw new Error('Failed to download pdf from local')
  }
}

const FilesContainer = () => {
  const CATEGORIES = [
    Category.DEFAULT,
    // Category.ALL,
    Category.LINKED,
    Category.UNLINKED,
  ]
  const navigate = useNavigate()
  const references = useAppSelector((state) => state.references.references)
  const comments = useAppSelector((state) => state.comments.comments)
  const referencesFileIds = references.map((ref) => ref.fileId)
  const dispatch = useAppDispatch()
  const [openPopup, setOpenPopup] = useState(false)
  const [pageLoading, setPageLoading] = useState(false)
  const [popupText, setPopupText] = useState<PopupTextType>({
    header: '',
    content: '',
    onClick: () => {},
  })
  const [upload, setUpload] = useState(false)
  const [uploadOnProgress, setUploadOnProgress] = useState(false)
  const deleteOption = useRef(0)
  const downloadOption = useRef(0)
  const moveToRef = useRef('/')
  const renameRef = useRef<HTMLInputElement>(null)
  const newFolderInputRef = useRef<HTMLInputElement>(null)
  const [fileCategory, setFileCategory] = useState<FileCategory>('ALL')
  const [selectMultipleFiles, setSelectMultipleFiles] = useState(false)
  const [selectedFileIds, { add, has, clear, remove }] = useSet(
    new Set<string>()
  )
  const cacheWorkerRef = useRef<Worker | null>(null)

  const {
    files,
    isError,
    refetch,
    setFiles,
    folderStack,
    setFolderStack,
    error,
    isLoading,
    isFetching,
  } = useFiles()

  useEffect(() => {
    const cacheWorker = new Worker(
      new URL('../../workers/cache_file_worker.ts', import.meta.url)
    )
    cacheWorkerRef.current = cacheWorker

    cacheWorker.onmessage = async (event) => {
      if (event.data.success) {
        await refetch().catch((err) => console.error(err))
      }
    }

    return () => cacheWorker.terminate()
  }, [refetch])

  useEffect(() => {
    const func = async () => {
      if (isLoading || isFetching) return

      // get all uncached files
      const uncachedFiles = files.filter(
        (file) =>
          file.type === 'FILE' &&
          !file.isLocal &&
          file.status === FileStatus.UNCACHED
      )

      if (uncachedFiles.length === 0) return

      // const uncachedMap = new Map<
      //   string,
      //   { file: DynamoFile; s3Data: GetFileType }
      // >()

      for (const file of uncachedFiles) {
        const res = await axios.get<{ data: GetFileType }>(
          `/v1/file/${file.fileId}/${file.userId}`
        )

        // uncachedMap.set(file.fileId, { file, s3Data: res.data.data })
        if (cacheWorkerRef.current) {
          // publish message to cache worker
          cacheWorkerRef.current.postMessage({
            file: JSON.stringify(file),
            s3Data: JSON.stringify(res.data.data),
          })
        }
      }

      // if (cacheWorkerRef.current) {
      //   cacheWorkerRef.current.postMessage({
      //     uncachedMap: JSON.stringify(Object.fromEntries(uncachedMap)),
      //   })
      // }
    }

    func().catch((err) => console.error(err))
  }, [isLoading, isFetching, files])

  const linkedFiles = files
    .filter((file) => file.type !== 'FOLDER')
    .filter(
      (file) =>
        referencesFileIds.includes(file.fileId) ||
        comments.some((topic) => topic.fileId === file.fileId)
    )

  // const linkedFiles = files.filter((file) =>
  //   referencesFileIds.includes(file.fileId)
  // )
  const unlinkedFiles = files
    .filter((file) => file.type !== 'FOLDER')
    .filter(
      (file) =>
        !referencesFileIds.includes(file.fileId) &&
        !comments.some((topic) => topic.fileId === file.fileId)
    )

  const parentFolderId = last(folderStack)?.id
  const folders = getFoldersV2(parentFolderId, files)
  const folderFiles = getFolderFilesV2(parentFolderId, files)
  const allFolderPaths = getAllPossiblePathsV2(files)

  const deletePDF = async (fileId: string) => {
    try {
      const file = files.find((file) => file.fileId === fileId)
      if (!file) throw new Error(`Invalid file id, fileId=${fileId}`)
      if (file.isLocal) {
        await removeAttachedFile(fileId)
      } else {
        await deleteFile(fileId)
      }
      await removeLocalFileRecordWithIds([fileId])
      await dispatch(deleteReferencesWithFileIds([fileId]))
      await refetch()
    } catch (err) {
      console.error(`Delete file error: ${err}`)
    } finally {
      setOpenPopup(false)
    }
  }

  const deleteAllFiles = async () => {
    const deletePromises = files.map((file) => {
      if (file.isLocal) {
        return removeAttachedFile(file.fileId)
      }
      return deleteFile(file.fileId)
    })
    return Promise.all(deletePromises)
      .then(async () => {
        await resetLocalFileRecord()
        await dispatch(resetReferences())
        await dispatch(resetComments())
        setFiles([])
        setFolderStack([])
      })
      .catch((err) => console.error(err))
      .finally(async () => {
        await refetch()
        setOpenPopup(false)
      })
  }

  const deleteFilesWithoutReferences = () => {
    const fileIds = unlinkedFiles.map((file) => file.fileId)
    const promises = unlinkedFiles.map((file) => {
      if (file.isLocal) {
        return removeAttachedFile(file.fileId)
      }
      return deleteFile(file.fileId)
    })
    return Promise.all(promises)
      .then(async () => {
        await removeLocalFileRecordWithIds(fileIds)
      })
      .catch((err) => console.error(err))
      .finally(async () => {
        await refetch()
        setOpenPopup(false)
      })
  }

  const deleteFilesWithReferences = () => {
    const fileIds = linkedFiles.map((file) => file.fileId)
    const promises = linkedFiles.map((file) => {
      if (file.isLocal) {
        return removeAttachedFile(file.fileId)
      }
      return deleteFile(file.fileId)
    })
    return Promise.all(promises)
      .then(async () => {
        await removeLocalFileRecordWithIds(fileIds)
        await dispatch(deleteReferencesWithFileIds(fileIds))
      })
      .catch((err) => console.error(err))
      .finally(async () => {
        await refetch()
        setOpenPopup(false)
      })
  }

  const DELETE_OPTIONS = [
    {
      label: 'Delete All Files',
      description: 'Delete all files',
      onClick: () => {
        deleteOption.current = 0
      },
    },
    {
      label: 'Delete Files With Links or Comments',
      description: 'Only delete files with links or comments',
      onClick: () => {
        deleteOption.current = 1
      },
    },
    {
      label: 'Delete Files Without Links and Comment',
      description: 'Only delete files without markup and comments',
      onClick: () => {
        deleteOption.current = 2
      },
    },
  ]

  const deleteFiles = async () => {
    const option = deleteOption.current
    if (option === 0) {
      await deleteAllFiles()
    } else if (option === 1) {
      await deleteFilesWithReferences()
    } else if (option === 2) {
      await deleteFilesWithoutReferences()
    }
    deleteOption.current = 0
    setOpenPopup(false)
  }

  const DOWNLAOD_OPTIONS: typeof DELETE_OPTIONS = [
    {
      label: 'Export All Files',
      description: 'Export all files without links to your local machine',
      onClick: () => {
        downloadOption.current = 0
      },
    },
    {
      label: 'Export all files with links',
      description: 'Export all files with links to your local machine',
      onClick: () => {
        downloadOption.current = 1
      },
    },
    {
      label: 'Export all files (ZIP)',
      description:
        'Export all files as ZIP without links to your local machine',
      onClick: () => {
        downloadOption.current = 3
      },
    },
    {
      label: 'Export all files with links (ZIP)',
      description: 'Export all files as ZIP with links to your local machine',
      onClick: () => {
        downloadOption.current = 4
      },
    },
    {
      label: 'Compact Export',
      description:
        'Combine all linked pages from all files into one PDF and export to your local machine',
      onClick: () => {
        downloadOption.current = 2
      },
    },
  ]

  const downloadFiles = () => {
    const option = downloadOption.current
    if (option === 0) {
      downloadAllFiles().catch((err) => console.log(err))
    } else if (option === 1) {
      exportAllLinkedFiles().catch((err) => console.log(err))
    } else if (option === 2) {
      compactExport().catch((err) => console.log(err))
    } else if (option === 3) {
      downloadAllFiles(true).catch((err) => console.log(err))
    } else if (option === 4) {
      exportAllLinkedFiles(true).catch((err) => console.log(err))
    }
    downloadOption.current = 0
    setOpenPopup(false)
  }

  const showDeleteOptionsPopup = () =>
    showPopup({
      header: (
        <div className="flex items-center p-2">
          <div className="flex items-center mr-1">
            <DeleteOutlinedIcon sx={{ fontSize: 20 }} />
          </div>
          <div>Delete options</div>
        </div>
      ),
      content: <CustomRadio options={DELETE_OPTIONS} label="DELETE" />,
      onClick: async (e) => {
        e.setSpinner(true)
        await deleteFiles()
        e.setSpinner(false)
        e.closePopup()
      },
      onSecondBtnClick: (e) => {
        deleteOption.current = 0
        e.closePopup()
        setOpenPopup(false)
      },
    })

  const showDownloadOptionsPopup = () =>
    showPopup({
      header: (
        <div className="flex items-center p-2">
          <div className="flex items-center mr-1">
            <FileUploadOutlinedIcon sx={{ fontSize: 20 }} />
          </div>
          <div>Export options</div>
        </div>
      ),
      content: <CustomRadio options={DOWNLAOD_OPTIONS} label="DOWNLOAD" />,
      onClick: () => downloadFiles(),
      onSecondBtnClick: () => {
        downloadOption.current = 0
        setOpenPopup(false)
      },
    })

  const onFileClickHelper = async (file: DynamoFile) => {
    // const f = await getFileV2(file.fileId)
    navigate(`/file/${file.fileId}`, {
      state: {
        fileId: file.fileId,
      },
    })
  }

  const onFileClick = (file: DynamoFile) => async () => {
    if (
      file.status !== FileStatus.SUCCEEDED &&
      file.status !== FileStatus.UPLOADING &&
      file.status !== FileStatus.MALICIOUS
    )
      return
    if (selectMultipleFiles) {
      if (has(file.fileId)) {
        remove(file.fileId)
      } else {
        add(file.fileId)
      }
      return
    }

    await onFileClickHelper(file).catch((err) => console.error(err))
  }

  const downloadFile = (file: DynamoFile) => async () => {
    try {
      let f: Downloadable | null = null
      if (file.isLocal) {
        f = await downloadPDFFromLocal(file)
      } else {
        f = await downloadPDFFromCloud(file)
      }
      if (f) {
        download(f.blob, f.fileName, f.type)
      }
    } catch (err) {
      console.error(`Download file error: ${err}`)
    }
  }

  const exportPDF = async (
    file: DynamoFile
  ): Promise<Downloadable | undefined> => {
    try {
      const pdfBlob = file.isLocal
        ? await exportPDFFromLocal(file)
        : await exportPDFFromCloud(file)
      if (!pdfBlob) throw new Error('Unable to export PDF')
      const filteredReferences = references.filter(
        (ref) => ref.fileId === file.fileId
      )
      const pdfBytes = await pdfBlob.arrayBuffer()
      const pdfDoc = await PDFDocument.load(pdfBytes)
      const pages = pdfDoc.getPages()

      for (let i = 0; i < pages.length; i++) {
        const { width, height } = pages[i].getSize()
        const rotation = pages[i].getRotation()
        const targetDegree_ = (((0 - rotation.angle) % 360) + 360) % 360
        // add comments to pdf page
        const filteredComments = comments.filter(
          (topic) => topic.fileId === file.fileId && topic.filePage === i + 1
        )

        for (const topic of filteredComments) {
          const contents = [...topic.comments].sort((a, b) =>
            dayjs(b.createdAt).diff(a.createdAt)
          )
          const textArr = contents.map(
            (comment) => `${comment.content}(${comment.createdBy})\n`
          )

          const target_degree = 360 - rotation.angle

          const [rotated_x, rotated_y, rotated_w, rotated_h] = rotateAVertex(
            topic.x,
            topic.y,
            topic.ocrW,
            topic.ocrH,
            target_degree
          )

          const x = (rotated_x * width) / rotated_w
          const y = height - (rotated_y * height) / rotated_h

          const annotation = pdfDoc.context.obj({
            Type: 'Annot',
            Subtype: 'Text',
            Open: false, // Is the annotation open by default?
            Name: 'Note', // Determines the icon to place in the document.
            Rect: [x, y, 30, 30], // The position of the annotation
            Contents: PDFString.of(textArr.join('')), // The annotation text
          })

          const annotationRef = pdfDoc.context.register(annotation)

          const annots = pages[i].node.lookup(PDFName.of('Annots'))
          if (annots) {
            ;(annots as PDFArray).push(annotationRef)
          } else
            pages[i].node.set(
              PDFName.of('Annots'),
              pdfDoc.context.obj([annotationRef])
            )
        }

        const pageRefs = filteredReferences.filter(
          (ref) => ref.filePage === i + 1
        )
        for (const ref of pageRefs) {
          const targetDegree = (((0 - ref.degree) % 360) + 360) % 360
          const newRef_ = rotateARectangle(ref, targetDegree)
          const newRef = rotateARectangle(newRef_, targetDegree_)
          const refX = newRef.x,
            refY = newRef.y + newRef.h,
            refH = newRef.h,
            refW = newRef.w,
            ocrH = newRef.ocrH,
            ocrW = newRef.ocrW

          pages[i].drawRectangle({
            x: (refX * width) / ocrW - 2,
            y: height - (refY * height) / ocrH - 2,
            width: (refW * width) / ocrW + 4,
            height: (refH * height) / ocrH + 4,
            ...getColor(ref.tag),
          })
        }
      }
      const updatedPDFBytes = await pdfDoc.save()
      return {
        blob: new Blob([updatedPDFBytes]),
        fileName: `${file.fileName}`,
        type: 'application/pdf',
      }
    } catch (err) {
      console.error(err)
    }
  }

  const downloadExportPdf = (file: DynamoFile) => async () => {
    const result = await exportPDF(file).catch((err) => console.error(err))
    if (result) download(result.blob, result.fileName, result.type)
  }

  const convertPDFToPdfDocument = (file: DynamoFile) => {
    return file.isLocal
      ? convertPDFToPdfDocumentLocal(file)
      : convertPDFToPdfDocumentCloud(file)
  }

  const compactExport = async () => {
    setPageLoading(true)
    try {
      const promises: ReturnType<typeof convertPDFToPdfDocument>[] = []
      for (const file of linkedFiles) {
        promises.push(convertPDFToPdfDocument(file))
      }
      const pdfDocuments = await Promise.all(promises)
      const newPDF = await PDFDocument.create()

      for (let i = 0; i < linkedFiles.length; i++) {
        const doc = pdfDocuments[i]
        const f = linkedFiles[i]
        const refs = references.filter((ref) => ref.fileId === f.fileId)
        const hashMap: Map<number, TextcutRectangle[]> = new Map()
        const filteredCommentTopics = comments.filter(
          (topic) => topic.fileId === f.fileId
        )
        refs.forEach((ref) => {
          if (hashMap.has(ref.filePage)) {
            const values = hashMap.get(ref.filePage)

            hashMap.set(ref.filePage, [...(values ?? []), ref])
          } else {
            hashMap.set(ref.filePage, [ref])
          }
        })
        for (const [k, values] of hashMap) {
          const page = doc.getPage(k - 1)
          const rotation = page.getRotation()
          const { width, height } = page.getSize()
          for (const v of values) {
            const filteredComments = filteredCommentTopics
              .filter((topic) => topic.filePage === k)
              .sort((a, b) => dayjs(b.createdAt).diff(a.createdAt))
            const targetDegree = (((0 - v.degree) % 360) + 360) % 360
            const newRef_ = rotateARectangle(v, targetDegree)
            const targetDegree_ = (((0 - rotation.angle) % 360) + 360) % 360
            const newRef = rotateARectangle(newRef_, targetDegree_)
            const refX = newRef.x,
              refY = newRef.y + newRef.h,
              refH = newRef.h,
              refW = newRef.w,
              ocrH = newRef.ocrH,
              ocrW = newRef.ocrW

            page.drawRectangle({
              x: (refX * width) / ocrW - 2,
              y: height - (refY * height) / ocrH - 2,
              width: (refW * width) / ocrW + 4,
              height: (refH * height) / ocrH + 4,
              ...getColor(v.tag),
            })
            for (const topic of filteredComments) {
              const contents = [...topic.comments].sort((a, b) =>
                dayjs(b.createdAt).diff(a.createdAt)
              )
              const textArr = contents.map(
                (comment) => `${comment.content}(${comment.createdBy})\n`
              )

              const target_degree = 360 - rotation.angle

              const [rotated_x, rotated_y, rotated_w, rotated_h] =
                rotateAVertex(
                  topic.x,
                  topic.y,
                  topic.ocrW,
                  topic.ocrH,
                  target_degree
                )

              const x = (rotated_x * width) / rotated_w
              const y = height - (rotated_y * height) / rotated_h

              const annotation = doc.context.obj({
                Type: 'Annot',
                Subtype: 'Text',
                Open: false, // Is the annotation open by default?
                Name: 'Note', // Determines the icon to place in the document.
                Rect: [x, y, 30, 30], // The position of the annotation
                Contents: PDFString.of(textArr.join('')), // The annotation text
              })

              const annotationRef = doc.context.register(annotation)

              const annots = page.node.lookup(PDFName.of('Annots'))
              if (annots) {
                ;(annots as PDFArray).push(annotationRef)
              } else
                page.node.set(
                  PDFName.of('Annots'),
                  doc.context.obj([annotationRef])
                )
            }
          }
          const [copyPage] = await newPDF.copyPages(doc, [k - 1])
          newPDF.addPage(copyPage)
        }
      }
      const pdfBytes = await newPDF.save()
      download(pdfBytes, `${nanoid()}`, 'application/pdf')
    } catch (err) {
      console.error(err)
    } finally {
      setPageLoading(false)
    }
  }

  const downloadAllFiles = async (zip = false) => {
    setPageLoading(true)
    const promises = files
      .filter((file) => file.type === 'FILE')
      .map((file) => {
        if (file.isLocal) {
          return downloadPDFFromLocal(file)
        }
        return downloadPDFFromCloud(file)
      })

    const result = await Promise.all(promises).catch((err) =>
      console.error(err)
    )
    if (!result) {
      setPageLoading(false)
      return
    }
    if (!zip) {
      result.forEach((re) => download(re.blob, re.fileName, re.type))
    } else {
      const zipFile = new JSZip()
      result.forEach((re) =>
        zipFile.file(re.fileName, re.blob, { binary: true })
      )
      const blob = await zipFile
        .generateAsync({ type: 'blob' })
        .catch((err) => console.error(err))
      if (blob) download(blob, 'files.zip', 'application/zip')
    }
    setPageLoading(false)
  }

  const exportAllLinkedFiles = async (zip = false) => {
    setPageLoading(true)
    const promises: Promise<Downloadable | undefined>[] = []
    for (const file of linkedFiles) {
      promises.push(exportPDF(file))
    }
    const results = await Promise.all(promises).catch((err) =>
      console.error(err)
    )
    if (!results) {
      setPageLoading(false)
      return
    }
    if (!zip) {
      results.forEach((re) => {
        if (re) download(re.blob, re.fileName, re.type)
      })
    } else {
      const zipFile = new JSZip()
      results.forEach((re) => {
        if (re) zipFile.file(re.fileName, re.blob, { binary: true })
      })
      const blob = await zipFile
        .generateAsync({ type: 'blob' })
        .catch((err) => console.error(err))
      if (blob) download(blob, 'files.zip', 'application/zip')
    }
    setPageLoading(false)
  }

  const showPopup = (popup: PopupTextType) => {
    setPopupText({
      header: popup.header,
      content: popup.content,
      onClick: popup.onClick,
      onSecondBtnClick: popup.onSecondBtnClick ?? undefined,
    })
    setOpenPopup(true)
  }

  const confirmSaveToLocal = async () => {
    setOpenPopup(false)
    setPageLoading(true)
    try {
      const filteredFiles = files.filter(
        (file) => file.status === FileStatus.SUCCEEDED
      )
      await savePdfsToWorkbook(filteredFiles)
      // const promises = []
      // for (const file of files) promises.push(deleteFile(file.fileId))
      // await Promise.all(promises)
      // await dispatch(setLocalMode())
      await autoSave()
      await refetch()
    } catch (err) {
      console.error(err)
    } finally {
      setPageLoading(false)
    }
  }

  const isExportable = (fileId: string) =>
    references.some((ref) => ref.fileId === fileId) ||
    comments.some((topic) => topic.fileId === fileId)

  const isTrimmable = (fileId: string) =>
    references.some((ref) => ref.fileId === fileId)

  const trimHelper = async (
    fileId: string,
    pdfBytes: ArrayBuffer,
    ocr: TestDataOcr
  ) => {
    const pdfDoc = await PDFDocument.load(pdfBytes)
    const newPDF = await PDFDocument.create()
    const pageSet = new Set<number>()
    const filteredRefs = references.filter((ref) => {
      if (ref.fileId === fileId) {
        pageSet.add(ref.filePage)
        return true
      }
      return false
    })
    const arr = Array.from(pageSet)
      .map((num) => num - 1)
      .sort((a, b) => a - b)
    const pages = await newPDF.copyPages(pdfDoc, arr)
    const newOcr = ocr.filter((data, idx) => arr.includes(idx))
    const newRefs: TextcutRectangle[] = []
    for (let i = 0; i < pages.length; i++) {
      newPDF.addPage(pages[i])
      const oldPage = arr[i] + 1
      const tmp = filteredRefs.filter((ref) => {
        if (ref.filePage === oldPage) return true
        return false
      })
      tmp.forEach((rect) => {
        const newRect: TextcutRectangle = { ...rect, filePage: i + 1 }
        newRefs.push(newRect)
      })
    }
    const savedPDF = await newPDF.save()
    const pdfBlob = new Blob([savedPDF.buffer as BlobPart], {
      type: 'application/pdf',
    })
    return [pdfBlob, newOcr, newRefs] as const
  }

  const trimCloudFile = async (fileId: string) => {
    const pageSet = new Set<number>()

    // Filter references that match the given fileId and add their pages to the set
    const filteredRefs = references.filter((ref) => {
      if (ref.fileId === fileId) {
        pageSet.add(ref.filePage)
        return true
      }
      return false
    })

    // Create an array of pages to keep, adjusting page numbers and sorting them
    const pagesToKeep = Array.from(pageSet)
      .map((num) => num - 1)
      .sort((a, b) => a - b)

    // Create a new array of references with updated page numbers
    const newRefs: TextcutRectangle[] = pagesToKeep
      .map((_, i) =>
        filteredRefs
          .filter((ref) => ref.filePage === pagesToKeep[i] + 1)
          .map((rect) => ({ ...rect, filePage: i + 1 }))
      )
      .flat()

    // Dispatch the updated references to the store
    await dispatch(updateRefereces(newRefs))

    return trimPages(fileId, pagesToKeep)
  }

  const trimLocalFile = async (fileId: string) => {
    await removeFileFromDB(fileId)
    const file = await fetchPDFFromLocal(fileId)
    const ocr = file?.ocr
    const pdfBlob = file?.pdfBlob
    if (!ocr || !pdfBlob) return
    const pdfBytes = await pdfBlob.arrayBuffer()
    const [newPdfBlob, newOcr, newRefs] = await trimHelper(
      fileId,
      pdfBytes,
      ocr
    )

    const newFile: typeof file = { ...file, pdfBlob: newPdfBlob, ocr: newOcr }

    return Promise.all([
      replaceLocalPDF(newFile),
      dispatch(updateRefereces(newRefs)),
    ])
  }

  const trimFile = async (fileId: string) => {
    setPageLoading(true)
    try {
      const file = files.find((file) => file.fileId === fileId)
      if (!file) throw new Error(`Invalid file id, fileId=${fileId}`)
      if (file.isLocal) {
        await trimLocalFile(fileId)
      } else {
        await trimCloudFile(fileId)
      }
      await refetch()
    } catch (err) {
      console.error(`trim file error: ${err}`)
    } finally {
      setPageLoading(false)
    }
  }

  const renderErrorTitle = () => {
    // if (localMode && localError) return getOfficeErrorTitle(localError.message)
    return isOfficeError(error)
      ? getOfficeErrorTitle(error.message)
      : 'Internal Error'
  }

  const deleteFolderHelper = async (
    folderItem: FolderItem,
    files: DynamoFile[]
  ) => {
    const nestedFiles = files.filter((file) => !!file.parentFolderId)
    const filtered: string[] = [folderItem.id]
    const findChildren = (parentFolderId: string | undefined) => {
      const childrens = nestedFiles.filter(
        (item) => item.parentFolderId && item.parentFolderId === parentFolderId
      )

      filtered.push(...childrens.map((child) => child.fileId))
      childrens.forEach((child) => findChildren(child.fileId))
    }

    findChildren(folderItem.id)

    for (const id of filtered) {
      await deletePDF(id)
    }

    setOpenPopup(false)
  }

  const saveFileToLocal = async (file: DynamoFile) => {
    try {
      if (!fileSizeLessThan5MB(file.originalFileSize)) {
        throw new Error(
          'Cannot save a file, which file size is greater than 5MB to workbook'
        )
      }
      await savePdfsToWorkbook([file])
    } catch (err) {
      console.error(`saveFileToLocal: ${err}`)
    } finally {
      await refetch()
    }
  }

  const findNestedFolders = (folderId: string) => {
    const folderIdSet = new Set<string>()
    const queue = new LinkList([folderId])
    while (queue.length) {
      const id = queue.popFront()
      if (id) {
        folderIdSet.add(id)
        const nestedFolders = files.filter((f) => f.parentFolderId === id)
        nestedFolders.forEach((folder) => {
          folderIdSet.add(folder.fileId)
        })
      }
    }
    return folderIdSet
  }

  const move = ({
    id,
    name,
    type,
  }: {
    id: string
    name: string
    type: 'file' | 'folder'
  }) => {
    const prefix = getPath(folderStack)
    const path = `${prefix}${prefix === '/' ? name : '/' + name}`

    let options = allFolderPaths.map(({ id, path }) => ({
      id,
      label: path,
      description: '',
      onClick: () => {
        moveToRef.current = id
      },
    }))

    if (type === 'folder') {
      const folderIdSet = findNestedFolders(id)
      options = options.filter((option) => !folderIdSet.has(option.id))
    }

    options.at(0)?.onClick()
    showPopup({
      header: <MovePopupHeader header={name} type={type} />,
      content: <MovePopupContent folderStack={folderStack} options={options} />,
      onClick: async (e) => {
        e.setSpinner(true)
        try {
          console.log(moveToRef)
          await updateParentFolder(id, moveToRef.current)
        } catch (err) {
          console.error(err)
          toast.error(
            `Internal error: cannot move files/folder from ${path} to ${moveToRef.current}`
          )
        } finally {
          await refetch()
        }

        e.setSpinner(false)
        e.closePopup()
      },
    })
  }

  const createNewFolder = () => {
    showPopup({
      header: <div>New folder</div>,
      content: (
        <div className="flex items-center justify-center">
          <Input
            ref={newFolderInputRef}
            type="text"
            placeholder="Folder name"
          />
        </div>
      ),
      onClick: async (e) => {
        const value = newFolderInputRef.current?.value
        if (!value) {
          toast.error('Folder name cannot be empty')

          return
        } else {
          e.setSpinner(true)
          try {
            const { oid } = await getOfficeRuntimeAccessToken()
            const folderId = await createFolder(parentFolderId, value)

            await saveFileRecordsToLocal([
              {
                fileId: folderId,
                userId: oid,
                status: FileStatus.SUCCEEDED,
              },
            ]).catch((err) => console.error('saveFileRecordsToLocal:', err))
          } catch (err) {
            console.error(err)
            toast.error(`Internal error: cannot create folder ${value}`)
          } finally {
            await refetch()
          }
          e.setSpinner(false)
          e.closePopup()
        }
      },
    })
  }

  const rename = (file: DynamoFile | undefined) => async () => {
    try {
      const value = renameRef.current?.value
      if (file && value) {
        await updateFileName({
          fileId: file.fileId,
          fileName: value,
          userId: file.userId,
          isFile: file.type === 'FILE' ? true : false,
        })
        const referencesToBeUpdate = references.filter(
          (ref) => ref.fileId === file.fileId
        )
        const mapped = referencesToBeUpdate.map((ref) => ({
          ...ref,
          fileName: value,
        }))
        await dispatch(updateRefereces(mapped))
        await refetch()
      }
      setOpenPopup(false)
    } catch (err) {
      console.error(`rename error=${err}`)
    }
  }

  const showRenamePopup = (fileId: string) => {
    const file = files.find((file) => file.fileId === fileId)
    if (!file) return
    showPopup({
      header: <div>Rename</div>,
      content: (
        <div className="flex items-center justify-center">
          <Input type="text" placeholder={file.fileName} ref={renameRef} />
        </div>
      ),
      onClick: rename(file),
    })
  }

  const addFileIdToSelectedFileRef = (fileId: string) => {
    if (has(fileId)) {
      remove(fileId)
    } else {
      add(fileId)
    }
  }

  const resetSelectedFileRef = () => {
    clear()
  }

  const fileHasChecked = (fileId: string) => has(fileId)

  const deleteSelections = async () => {
    try {
      const filesArr: DynamoFile[] = []
      const folderArr: DynamoFile[] = []

      files.forEach((file) => {
        if (selectedFileIds.has(file.fileId)) {
          if (file.type === 'FILE') {
            filesArr.push(file)
          } else {
            folderArr.push(file)
          }
        }
      })

      const folderIdSet = new Set(folderArr.map((folder) => folder.fileId))

      const nestedFiles = files.filter(
        (file) => file.parentFolderId && folderIdSet.has(file.parentFolderId)
      )

      const toBeDeleted = new Set([
        ...filesArr.map((file) => file.fileId),
        ...folderIdSet,
        ...nestedFiles.map((file) => file.fileId),
      ])

      const promises = [...toBeDeleted].map((id) => deletePDF(id))

      await Promise.all(promises)
      await refetch()
    } catch (error) {
      console.error('deleteSelections error:', error)
    } finally {
      setOpenPopup(false)
      setSelectMultipleFiles(false)
      clear()
    }
  }

  const showDeleteSelectionsPopup = () => {
    if (selectedFileIds.size) {
      showPopup({
        header: 'Deletion',
        content: `${selectedFileIds.size} items will be deleted`,
        onClick: () => deleteSelections(),
      })
    } else {
      showPopup({
        header: 'Deletion',
        content: `No item has been selected`,
        onClick: () => {
          setOpenPopup(false)
        },
      })
    }
  }

  const moveSelection = async () => {
    try {
      const promises = [...selectedFileIds].map((id) =>
        updateParentFolder(id, moveToRef.current)
      )
      await Promise.all(promises)
      await refetch()
    } catch (error) {
      console.error(error)
    } finally {
      setOpenPopup(false)
      setSelectMultipleFiles(false)
      clear()
    }
  }

  const showMoveSelectionPopup = () => {
    if (selectedFileIds.size) {
      const folders = files.filter(
        (file) => file.type === 'FOLDER' && selectedFileIds.has(file.fileId)
      )
      let options = allFolderPaths.map(({ id, path }) => ({
        id,
        label: path,
        description: '',
        onClick: () => {
          moveToRef.current = id
        },
      }))

      for (const folder of folders) {
        const folderIdSet = findNestedFolders(folder.fileId)
        options = options.filter((option) => !folderIdSet.has(option.id))
      }

      options.at(0)?.onClick()
      showPopup({
        header: `Move ${selectedFileIds.size} item(s)`,
        content: (
          <MovePopupContent folderStack={folderStack} options={options} />
        ),
        onClick: () => moveSelection(),
      })
    } else {
      showPopup({
        header: 'Deletion',
        content: `No item has been selected`,
        onClick: () => {
          setOpenPopup(false)
        },
      })
    }
  }

  const onFolderClick = (
    folder: FolderItem,
    setFolderStack: Dispatch<React.SetStateAction<FolderItem[]>> | undefined
  ) => {
    if (selectMultipleFiles) {
      if (has(folder.id)) {
        remove(folder.id)
      } else {
        add(folder.id)
      }
    } else if (setFolderStack) {
      setFolderStack([...(folderStack ?? []), folder])
    }
  }

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 0.5,
        delay: 0.3,
        ease: [0, 0.71, 0.2, 1.01],
      }}
      className="h-full w-full"
    >
      <ToastContainer />
      <Popup
        isOpen={openPopup}
        setIsOpen={setOpenPopup}
        popupHeader={popupText.header}
        popupContent={popupText.content}
        firstBtn={{ text: 'Confirm', onClick: popupText.onClick }}
        secondBtn={{
          text: 'Cancel',
          onClick: popupText.onSecondBtnClick
            ? popupText.onSecondBtnClick
            : () => setOpenPopup(false),
        }}
      />
      <div className="sticky top-0 z-50 w-full bg-white shadow-sm min-h-[56px] max-h-[10%] min-w-[300px]">
        <div className="flex w-full min-w-[300px] h-fit justify-between space-x-6 p-1">
          {!upload && (
            <div className="text-lg font-bold p-2">
              <h1>Files</h1>
            </div>
          )}
          {upload && !uploadOnProgress && (
            <div className="text-md font-bold p-2">
              <button
                className="flex items-center transition ease-in-out delay-100 hover:scale-105"
                onClick={() => setUpload(false)}
              >
                <ArrowBackOutlinedIcon sx={{ fontSize: 20 }} />
                <h1 className="ml-1">Back to files</h1>
              </button>
            </div>
          )}
          <MobileHeader
            createNewFolder={() => createNewFolder()}
            setUpload={() => setUpload(true)}
            showDeleteOptions={showDeleteOptionsPopup}
            showDownloadOptions={showDownloadOptionsPopup}
            showIncludeInExcel={() =>
              showPopup({
                ...TRANSFER,
                onClick: async (e) => {
                  e.setSpinner(true)
                  await confirmSaveToLocal()
                  e.setSpinner(false)
                  e.closePopup()
                },
              })
            }
            upload={upload}
            uploadOnProgress={uploadOnProgress}
            numberOfFiles={files.length}
            allFilesProcessed={files.every(
              (file) => file.status !== FileStatus.PROCESSING
            )}
            setSelectMultipleFiles={setSelectMultipleFiles}
          />
          <div className="hidden sm:flex  justify-between items-center p-2 gap-2 ">
            {!upload && !uploadOnProgress && (
              <div className="relative flex justify-center group mr-2 transition ease-in-out delay-100 hover:scale-110">
                <button
                  className="hover:bg-gray-200 text-sm text-gray-800 font-bold py-1 px-1 rounded inline-flex items-center"
                  onClick={() => setSelectMultipleFiles(true)}
                >
                  <CheckBoxOutlinedIcon sx={{ fontSize: '20px' }} />
                </button>
                <div className="absolute w-max top-14 scale-0 rounded bg-neutral-700 p-2 text-xs text-white group-hover:scale-100 before:w-2 before:h-2 before:rotate-45 before:bg-neutral-700 before:absolute before:z-[-1] before:-top-1 before:left-0 before:right-0 before:mx-auto">
                  Select files
                </div>
              </div>
            )}

            {!upload && !uploadOnProgress && (
              <div className="relative flex justify-center group mr-2 transition ease-in-out delay-100 hover:scale-110">
                <button
                  className="hover:bg-gray-200 text-sm text-gray-800 font-bold py-1 px-1 rounded inline-flex items-center"
                  onClick={() => createNewFolder()}
                >
                  <CreateNewFolderOutlinedIcon sx={{ fontSize: '20px' }} />
                </button>
                <div className="absolute w-max top-14 scale-0 rounded bg-neutral-700 p-2 text-xs text-white group-hover:scale-100 before:w-2 before:h-2 before:rotate-45 before:bg-neutral-700 before:absolute before:z-[-1] before:-top-1 before:left-0 before:right-0 before:mx-auto">
                  New folder
                </div>
              </div>
            )}
            {!upload &&
              !uploadOnProgress &&
              !files.some((file) => file.status === FileStatus.PROCESSING) && (
                <div className="relative flex justify-center group mr-2 transition ease-in-out delay-100 hover:scale-110">
                  <button
                    className="hover:bg-gray-200 text-sm text-gray-800 font-bold py-1 px-1 rounded inline-flex items-center"
                    onClick={() =>
                      showPopup({
                        ...TRANSFER,
                        onClick: async (e) => {
                          e.setSpinner(true)
                          await confirmSaveToLocal()
                          e.setSpinner(false)
                          e.closePopup()
                        },
                      })
                    }
                  >
                    <CloudDownloadOutlinedIcon sx={{ fontSize: '20px' }} />
                  </button>
                  <div className="absolute w-max top-14 scale-0 rounded bg-neutral-700 p-2 text-xs text-white group-hover:scale-100 before:w-2 before:h-2 before:rotate-45 before:bg-neutral-700 before:absolute before:z-[-1] before:-top-1 before:left-0 before:right-0 before:mx-auto">
                    Include in excel
                  </div>
                </div>
              )}
            {files.length !== 0 &&
              !upload &&
              !files.some((file) => file.status === FileStatus.PROCESSING) && (
                <div className="relative flex justify-center group  mr-2 transition ease-in-out delay-100 hover:scale-110">
                  <button
                    className="hover:bg-gray-200 text-sm text-gray-800 font-bold py-1 px-1 rounded inline-flex items-center"
                    onClick={showDownloadOptionsPopup}
                  >
                    <FileUploadOutlinedIcon sx={{ fontSize: '20px' }} />
                  </button>
                  <div className="absolute w-max top-14 scale-0 rounded bg-neutral-700 p-2 text-xs text-white group-hover:scale-100 before:w-2 before:h-2 before:rotate-45 before:bg-neutral-700 before:absolute before:z-[-1] before:-top-1 before:left-0 before:right-0 before:mx-auto">
                    Export options
                  </div>
                </div>
              )}
            {files.length !== 0 &&
              !upload &&
              !files.some((file) => file.status === FileStatus.PROCESSING) && (
                <div className="relative flex justify-center group  mr-2 transition ease-in-out delay-100 hover:scale-110">
                  <button
                    className="hover:bg-gray-200 text-sm text-gray-800 font-bold py-1 px-1 rounded inline-flex items-center"
                    onClick={showDeleteOptionsPopup}
                  >
                    <DeleteOutlinedIcon sx={{ fontSize: '20px' }} />
                  </button>
                  <div className="absolute w-max top-14 scale-0 rounded bg-neutral-700 p-2 text-xs text-white group-hover:scale-100 before:w-2 before:h-2 before:rotate-45 before:bg-neutral-700 before:absolute before:z-[-1] before:-top-1 before:left-0  before:right-0 before:mx-auto">
                    Delete options
                  </div>
                </div>
              )}
            {!upload && !uploadOnProgress && (
              <div className="transition ease-in-out delay-100 hover:scale-110">
                <button
                  onClick={() => setUpload(true)}
                  className="text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-teal-300 font-medium rounded-full text-xs px-2 py-2 text-center"
                >
                  Upload Files
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {upload && (
        <UploadContainer
          refetchFiles={refetch}
          setUpload={setUpload}
          loading={uploadOnProgress}
          setLoading={setUploadOnProgress}
          folderStack={folderStack}
        />
      )}

      {!upload && (
        <div className="flex flex-col p-2 w-full h-full sm:hidden gap-2">
          {!selectMultipleFiles && (
            <div>
              <Select
                name="category"
                aria-label="File category"
                onChange={(e) =>
                  setFileCategory(e.target.value as FileCategory)
                }
              >
                <option value="ALL">All</option>
                <option value="LINKED">Linked</option>
                <option value="UNLINKED">Unlinked</option>
              </Select>
            </div>
          )}
          {isError && (
            <div className="flex flex-col p-4 w-full">
              <ErrorMsg
                refetch={refetch}
                title={renderErrorTitle()}
                content={GENERAL_ERR_CONTENT}
              />
            </div>
          )}
          {(pageLoading || isLoading || uploadOnProgress) && (
            <SmallScreenSkeleton />
          )}

          {!isError &&
            !pageLoading &&
            !isLoading &&
            !uploadOnProgress &&
            selectMultipleFiles && (
              <MultiSelectionBar
                setSelectMultipleFiles={setSelectMultipleFiles}
                numberOfSelections={selectedFileIds.size}
                resetSelectedFileRef={resetSelectedFileRef}
                showDeleteSelectionsPopup={showDeleteSelectionsPopup}
                showMoveSelectionPopup={showMoveSelectionPopup}
              />
            )}

          {!isError && !pageLoading && !isLoading && !uploadOnProgress && (
            <div className="rounded-xl bg-white p-3 mb-3 w-full h-5/6 ring-white/60 ring-offset-2 ring-offset-green-400 focus:outline-none focus:ring-2  overflow-y-auto">
              <MobileFileList
                folderStack={fileCategory === 'ALL' ? folderStack : []}
                setFolderStack={setFolderStack}
                folders={fileCategory === 'ALL' ? folders : []}
                files={
                  fileCategory === 'ALL'
                    ? folderFiles
                    : fileCategory === 'LINKED'
                    ? linkedFiles
                    : unlinkedFiles
                }
                deletePDF={deletePDF}
                downloadFile={downloadFile}
                exportPDF={downloadExportPdf}
                isExportable={isExportable}
                isTrimmable={isTrimmable}
                onFileClick={onFileClick}
                showPopup={showPopup}
                trimFile={trimFile}
                references={references}
                comments={comments}
                deleteFolder={(folderItem) => {
                  showPopup({
                    header: 'Delete Folder',
                    content: 'Are you sure you want to delete this folder?',
                    onClick: (e) => {
                      e.setSpinner(true)
                      deleteFolderHelper(folderItem, files).finally(() => {
                        e.setSpinner(false)
                        e.closePopup()
                      })
                    },
                  })
                }}
                movable={true}
                move={move}
                savePdfsToWorkbook={saveFileToLocal}
                showRenamePopup={showRenamePopup}
                addFileIdToSelectedFileRef={addFileIdToSelectedFileRef}
                fileHasChecked={fileHasChecked}
                selectMultipleFiles={selectMultipleFiles}
                onFolderClick={onFolderClick}
              />
            </div>
          )}
        </div>
      )}

      {!upload && (
        <div className="hidden sm:flex flex-col w-full h-full justify-start items-center px-4 mt-4">
          <Tab.Group as={Fragment}>
            {!selectMultipleFiles && (
              <Tab.List
                defaultValue={0}
                className="flex min-w-[300px] w-full h-fit max-w-md space-x-1 rounded-xl bg-[#81D8D0]/20 p-1"
              >
                {CATEGORIES.map((category) => (
                  <Tab
                    as={motion.button}
                    whileHover={{ scale: 1.1 }}
                    transition={{
                      type: 'spring',
                      stiffness: 300,
                    }}
                    key={category}
                    className={({ selected }) =>
                      classNames(
                        'w-full rounded-lg py-2.5 text-sm font-medium leading-5',
                        'ring-white/60 ring-offset-2 ring-offset-green-400 focus:outline-none focus:ring-2',
                        selected
                          ? 'bg-white text-green-700 shadow'
                          : 'text-white-100 hover:bg-white/[0.12] hover:text-black hover:font-semibold'
                      )
                    }
                  >
                    {category}
                  </Tab>
                ))}
              </Tab.List>
            )}
            {selectMultipleFiles && (
              <MultiSelectionBar
                setSelectMultipleFiles={setSelectMultipleFiles}
                numberOfSelections={selectedFileIds.size}
                resetSelectedFileRef={resetSelectedFileRef}
                showDeleteSelectionsPopup={showDeleteSelectionsPopup}
                showMoveSelectionPopup={showMoveSelectionPopup}
              />
            )}
            {(pageLoading || isLoading || uploadOnProgress) && <Skeleton />}
            {isError && (
              <div className="flex flex-col p-4 w-full">
                <ErrorMsg
                  refetch={refetch}
                  title={renderErrorTitle()}
                  content={GENERAL_ERR_CONTENT}
                />
              </div>
            )}

            {!isError && !pageLoading && !isLoading && (
              <Tab.Panels className="mt-2 flex min-h-0 max-h-[calc(100%-10rem)] w-full min-w-[300px]">
                <Tab.Panel
                  hidden={files.length === 0}
                  className={classNames(
                    'rounded-xl bg-white p-3 mb-2 w-full h-full  ',
                    'ring-white/60 ring-offset-2 ring-offset-green-400 focus:outline-none focus:ring-2 overflow-y-auto'
                  )}
                >
                  {files.length > 0 && (
                    <FileUnorderedList
                      folderStack={folderStack}
                      setFolderStack={setFolderStack}
                      // folders={getFolders(folderStack, folders)}
                      // files={getFilesFromFolder(folderStack, folders, files)}
                      folders={folders}
                      files={folderFiles}
                      deletePDF={deletePDF}
                      downloadFile={downloadFile}
                      exportPDF={downloadExportPdf}
                      isExportable={isExportable}
                      isTrimmable={isTrimmable}
                      onFileClick={onFileClick}
                      showPopup={showPopup}
                      trimFile={trimFile}
                      references={references}
                      comments={comments}
                      deleteFolder={(folderItem) => {
                        showPopup({
                          header: 'Delete Folder',
                          content:
                            'Are you sure you want to delete this folder?',
                          onClick: (e) => {
                            e.setSpinner(true)
                            deleteFolderHelper(folderItem, files).finally(
                              () => {
                                e.setSpinner(false)
                                e.closePopup()
                              }
                            )
                          },
                        })
                      }}
                      movable={true}
                      move={move}
                      savePdfsToWorkbook={saveFileToLocal}
                      showRenamePopup={showRenamePopup}
                      selectMultipleFiles={selectMultipleFiles}
                      addFileIdToSelectedFileRef={addFileIdToSelectedFileRef}
                      fileHasChecked={fileHasChecked}
                      onFolderClick={onFolderClick}
                    />
                  )}
                </Tab.Panel>

                {/* <Tab.Panel
            hidden={files.length === 0}
            className={classNames(
              'rounded-xl bg-white p-3 mb-3 w-full h-full',
              'ring-white/60 ring-offset-2 ring-offset-green-400 focus:outline-none focus:ring-2 overflow-y-auto'
            )}
          >
            {files.length > 0 && (
              <FileUnorderedList
                files={files}
                deletePDF={deletePDF}
                downloadFile={downloadFile}
                exportPDF={exportPDF}
                isExportable={isExportable}
                isTrimmable={isTrimmable}
                onFileClick={onFileClick}
                showPopup={showPopup}
                trimFile={trimFile}
                localMode={localMode}
              />
            )}
          </Tab.Panel> */}

                <Tab.Panel
                  hidden={linkedFiles.length === 0}
                  className={classNames(
                    'rounded-xl bg-white p-3 mb-3 w-full h-full',
                    'ring-white/60 ring-offset-2 ring-offset-green-400 focus:outline-none focus:ring-2  overflow-y-auto'
                  )}
                >
                  {linkedFiles.length > 0 && (
                    <FileUnorderedList
                      files={linkedFiles}
                      deletePDF={deletePDF}
                      downloadFile={downloadFile}
                      exportPDF={downloadExportPdf}
                      isExportable={isExportable}
                      isTrimmable={isTrimmable}
                      onFileClick={onFileClick}
                      showPopup={showPopup}
                      trimFile={trimFile}
                      references={references}
                      comments={comments}
                      savePdfsToWorkbook={saveFileToLocal}
                      showRenamePopup={showRenamePopup}
                      selectMultipleFiles={selectMultipleFiles}
                      addFileIdToSelectedFileRef={addFileIdToSelectedFileRef}
                      fileHasChecked={fileHasChecked}
                    />
                  )}
                </Tab.Panel>

                <Tab.Panel
                  hidden={unlinkedFiles.length === 0}
                  className={classNames(
                    'rounded-xl bg-white p-3 mb-3 w-full h-full',
                    'ring-white/60 ring-offset-2 ring-offset-green-400 focus:outline-none focus:ring-2 overflow-y-auto '
                  )}
                >
                  {unlinkedFiles.length > 0 && (
                    <FileUnorderedList
                      files={unlinkedFiles}
                      deletePDF={deletePDF}
                      downloadFile={downloadFile}
                      exportPDF={downloadExportPdf}
                      isExportable={isExportable}
                      isTrimmable={isTrimmable}
                      onFileClick={onFileClick}
                      showPopup={showPopup}
                      trimFile={trimFile}
                      references={references}
                      comments={comments}
                      savePdfsToWorkbook={saveFileToLocal}
                      showRenamePopup={showRenamePopup}
                      selectMultipleFiles={selectMultipleFiles}
                      addFileIdToSelectedFileRef={addFileIdToSelectedFileRef}
                      fileHasChecked={fileHasChecked}
                    />
                  )}
                </Tab.Panel>
              </Tab.Panels>
            )}
          </Tab.Group>
          {/* {estimationTimeMs > 0 && (
            <footer className="absolute flex justify-end bottom-0 bg-white w-full rounded-t-3xl h-[60px]">
              <FileProgressContainer
                totalFiles={filesForEstimation.length}
                processedFiles={processedFiles}
                remainingTimeMs={estimationTimeMs}
              />
            </footer>
          )} */}
        </div>
      )}
    </motion.div>
  )
}

export default FilesContainer
