import currency from 'currency.js'
import elasticlunr from 'elasticlunr'
import { TextBlock } from '../types'
import { forEach } from 'lodash'

export const buildNumberAndDateMap = (
  lines: Map<string, TextBlock>,
  words: Map<string, TextBlock>,
  dateCache: string[]
) => {
  const dateMap = new Map<string, string>()
  dateCache.forEach((t) => {
    const [date, id] = t.split('::')
    dateMap.set(id, date)
  })
  const numberMap = new Map<string, string>()
  forEach([...words.values(), ...lines.values()], (block) => {
    if (dateMap.has(block.id)) {
      return {
        id: block.id,
        text: dateMap.get(block.id)!,
      }
    }
    const num = currency(block.description)
    if (!isNaN(num.value) && num.value !== 0) {
      numberMap.set(block.id, String(num.value))
      return {
        id: block.id,
        text: String(num.value),
      }
    }
    return {
      id: block.id,
      text: block.description,
    }
  })

  return { numberMap, dateMap }
}

export const buildSearchIndex = (
  words: Map<string, TextBlock>,
  lines: Map<string, TextBlock>,
  dateBlocks: string[]
) => {
  const searchArray = [...words.values(), ...lines.values()].map((block) => ({
    ...block,
    // formmatedDescription: block.description.toLocaleLowerCase(), // performace issue with toLocaleLowerCase
    formmatedDescription: block.description.toLowerCase(),
  }))

  const { numberMap, dateMap } = buildNumberAndDateMap(lines, words, dateBlocks)
  const documents = searchArray.map((item) => {
    if (dateMap.has(item.id)) {
      return {
        id: item.id,
        text: dateMap.get(item.id)!,
      }
    }
    const num = currency(item.description)
    if (!isNaN(num.value) && num.value !== 0) {
      numberMap.set(item.id, String(num.value))
      return {
        id: item.id,
        text: String(num.value),
      }
    }
    return {
      id: item.id,
      text: item.description,
    }
  })

  // console.log(documents)

  const index = elasticlunr<{
    id: string
    text: string
  }>((l) => {
    l.setRef('id')
    l.addField('text')
    l.saveDocument(false)
    documents.forEach((doc) => l.addDoc(doc))
  })

  return index
}
