/**
 *
 * @param str
 * @returns boolean
 *
 * $ (Dollar: USD, CAD, AUD, etc.)
 * € (Euro)
 * £ (British Pound)
 * ¥ (Japanese Yen, Chinese Yuan)
 * ₹ (Indian Rupee)
 * ₽ (Russian Ruble)
 * ₩ (South Korean Won)
 * ₺ (Turkish Lira)
 * ₴ (Ukrainian Hryvnia)
 * ₱ (Peso)
 * RM (Malaysian Ringgit)
 * R (South African Rand)
 */
export const containsCurrencySymbol = (str: string) => {
  const currencyRegex = /(?:RM|R|[$€£¥₹₽₩₺₴₱])/
  return currencyRegex.test(str)
}
