import { filter } from 'lodash'
import {
  DataMatchFolderStruct,
  DataMatchOutput,
  DataMatchStatus,
  DynamoFile,
  EPlusFolder,
  TestFileCheck,
} from '../types'
import { isTextBlock } from './guards'
import { getFileAndOcrBatch } from './common'
import { getColumnSize, lettersToNumber } from '../workbook'
import { getAllFileIdsInAFolder } from './file_management'
import { extractAndParseDate, isValidDate } from './date-util'
import { containsCurrencySymbol } from './currency'
import currency from 'currency.js'

export const formatDataMatchResult = (
  result: DataMatchOutput,
  inputValues: {
    textResult: string[][]
    valueResult: any[][]
  }
): any[][] => {
  return result.map((re, i) => {
    if (re.length > 1) {
      const returnArray: any[] = re[0].map((_) => null)
      for (let j = 0; j < returnArray.length; j++) {
        const allNull = re.every((val) => val[j] === null)
        if (allNull) {
          returnArray[j] = null
        } else {
          const noneNullCount = re.filter((val) => val[j] !== null).length
          let noneNull: any = null
          if (noneNullCount > 1) {
            noneNull = DataMatchStatus.DUPLICATE
          } else {
            noneNull = re.find((val) => val[j] !== null)
          }
          if (noneNull === DataMatchStatus.DUPLICATE) {
            returnArray[j] = noneNull
          } else if (noneNull === null) {
            returnArray[j] = null
          } else if (isTextBlock(noneNull)) {
            if (isValidDate(noneNull.description).valid) {
              returnArray[j] = extractAndParseDate(noneNull.description)
            } else if (containsCurrencySymbol(noneNull.description)) {
              returnArray[j] = currency(noneNull.description)
            } else {
              returnArray[j] = noneNull.description
            }
          } else returnArray[j] = ''
        }
      }
      return returnArray
    }
    return re[0].map((val, j) => {
      if (val == null) return ''
      else if (typeof val === 'string') return val
      else if (isTextBlock(val)) {
        if (isValidDate(val.description).valid) {
          return extractAndParseDate(val.description)
        } else if (containsCurrencySymbol(val.description)) {
          return currency(val.description).value
        }
        return val.description
      } else return ''
    })
  })
}

export const getInputFiles = async (
  files: TestFileCheck[],
  isLocalMode: boolean
) => {
  const inputFiles = filter(files, (file) => file.checked)
  const fileIds = inputFiles.map((file) => file.id)
  const results = await getFileAndOcrBatch(fileIds, isLocalMode)
  const resultHashmap = new Map<string, (typeof results)[0]>()
  results.forEach((result) => {
    const [id, , ,] = result
    resultHashmap.set(id, result)
  })

  inputFiles.forEach((file) => {
    const fileData = resultHashmap.get(file.id)
    if (!fileData) throw new Error(`Internal error: file ${file.id} is invalid`)
    const [, , ocr, dateCache] = fileData
    file.ocrJson = ocr
    file.dateCache = dateCache
  })

  return inputFiles
}

export const getDataMatchFolderStruct = async ({
  data,
  folders,
  inputRange,
  outputRange,
  selectedFolders,
}: {
  inputRange: string
  outputRange: string[]
  selectedFolders: string[]
  folders: EPlusFolder[]
  data: DynamoFile[]
}) => {
  const inputColCount = await getColumnSize(inputRange)

  const outputRanges: string[][] = []
  for (let i = 0; i < outputRange.length; i += inputColCount) {
    const slice = outputRange.slice(i, inputColCount + i)
    outputRanges.push(slice)
  }

  const dataMatchFolderStructs: DataMatchFolderStruct[] = []
  for (const selectedFolderId of selectedFolders) {
    const folder = folders.find((folder) => folder.fileId === selectedFolderId)
    if (!folder) return []
    const fileIds = getAllFileIdsInAFolder(folder.fileId, data)
    dataMatchFolderStructs.push({
      id: folder.fileId,
      fileIds,
      name: folder.folderName,
      outputColumns: [],
      result: [],
      formattedResult: [],
      excelRanges: [],
      numberFormat: [],
    })
  }

  for (let i = 0; i < outputRanges.length; i++) {
    dataMatchFolderStructs[i].outputColumns.push(...outputRanges[i])
  }
  return dataMatchFolderStructs
}

export const getExcelOutputRanges = ({
  filteredOutputRange,
  formattedResult,
  inputRange,
  worksheet,
}: {
  formattedResult: any[][]
  filteredOutputRange: string[]
  inputRange: Excel.Range
  worksheet: Excel.Worksheet
}) => {
  const outputRanges: Excel.Range[][] = []

  for (let i = 0; i < formattedResult.length; i++) {
    const row: Excel.Range[] = []
    for (let j = 0; j < filteredOutputRange.length; j++) {
      const letter = lettersToNumber(filteredOutputRange[j])
      const range = worksheet.getRangeByIndexes(
        inputRange.rowIndex + i,
        letter - 1,
        1,
        1
      )
      range.load(['address', 'rowIndex', 'columnIndex', 'values'])
      row.push(range)
    }
    outputRanges.push(row)
  }

  return outputRanges
}

export const removeSpaces = (str: string) => {
  return str.replace(/\s+/g, '')
}
