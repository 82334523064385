import dayjs from 'dayjs'
import { db } from '../db/db'
import { ExtractlyPlusFile } from '../types'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export const addFileToDB = (file: ExtractlyPlusFile) => db.files.put(file)

export const fileInDB = (fileId: string) =>
  db.files.where({ id: fileId }).count()

export const getFileFromDB = (fileId: string) => db.files.get(fileId)

export const getFilesFromDB = (fileIds: string[]) =>
  db.files.where('id').anyOf(fileIds).toArray()

export const removeFileFromDB = (fileId: string) => db.files.delete(fileId)

export const revalidateFiles = async (day: number) => {
  const sevenDaysAgoDayjs = dayjs.utc().subtract(day, 'day') // dayjs object in UTC
  const sevenDaysAgoDate = sevenDaysAgoDayjs.toDate()
  console.log('revalidateFiles...')
  const toBeDeletedKeys = await db.files
    .where('createdAt')
    .below(sevenDaysAgoDate)
    .primaryKeys()
  console.log('toBeDeleted:', toBeDeletedKeys.length)
  if (toBeDeletedKeys.length) await db.files.bulkDelete(toBeDeletedKeys)
}
