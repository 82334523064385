import { useCallback, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../dispatch'
import {
  deleteReferencesWithBindingIds,
  updateRefereces,
} from '../slice/referenceSlice'
import { releaseBindingIdAsync } from '../workbook'
import { TextcutRectangle } from '../types'
import { updateUndoStack } from '../slice/undoSlice'

const onRangeEdit = (sheetId: string, rangeAddress: string, rects: TextcutRectangle[]) => Excel.run(async ctx => {
  const sheet = ctx.workbook.worksheets.getItemOrNullObject(sheetId)
  sheet.load(['isNullObject'])
  await ctx.sync()

  if (sheet.isNullObject) return

  const referencesInGivenSheet = rects.filter(rect => rect.sheetId === sheetId)
  const range = sheet.getRange(rangeAddress)
  range.load('isNullObject')
  const hashmap = new Map<string, Excel.Range>()
  for (const reference of referencesInGivenSheet) {
    const referenceRange = sheet.getRange(reference.rangeAddr)
      .load(['isNullObject', 'values'])
    hashmap.set(reference.bindingId, referenceRange)
  }
  await ctx.sync()

  if (range.isNullObject) return

  const intersectionRangeMap = new Map<string, Excel.Range>()
  for (const [k, r] of hashmap) {
    if (r.isNullObject) continue
    const intersection = range.getIntersectionOrNullObject(r)
    intersection.load('isNullObject')
    intersectionRangeMap.set(k, intersection)
  }
  await ctx.sync()

  const hashset = new Set<string>()
  for (const [k, intersection] of intersectionRangeMap) {
    if (intersection.isNullObject) continue
    const referenceRange = hashmap.get(k)
    if (referenceRange?.values.every(row => row.every(val => val === ''))) {
      hashset.add(k)
    }
  }
  const bindingMap = new Map<string, Excel.Binding>()
  for (const id of hashset) {
    const binding = ctx.workbook.bindings.getItemOrNullObject(id)
    binding.load('isNullObject')
    bindingMap.set(id, binding)
  }
  await ctx.sync().catch((err) => console.error(err))

  const releaseBindingQueue = []
  for (const [k, v] of bindingMap) {
    try {
      if (v.isNullObject) continue
      v.delete()
    } catch (err) {
      console.error('release binding:', k)
      releaseBindingQueue.push(k)
    }
  }
  for (const id of releaseBindingQueue) {
    await releaseBindingIdAsync(id).catch((err) => console.error(err))
  }
  await ctx.sync()

  return [...hashset]
})

const useOnChangedReceiver = () => {
  const references = useAppSelector((state) => state.references.references)
  const undos = useAppSelector((state) => state.undo.undoStack)
  const dispatch = useAppDispatch()

  const updateReferencesHelper = useCallback(
    (references: TextcutRectangle[]) =>
      Excel.run(async (ctx) => {
        const bindings = ctx.workbook.bindings
        bindings.load('items')
        await ctx.sync()
        const hashMap = new Map<string, Excel.Range>()
        bindings.items.forEach((item) => item.load('id'))
        await ctx.sync()
        bindings.items.forEach((item) => {
          const range = item.getRange()
          range.load('address')
          hashMap.set(item.id, range)
        })
        await ctx.sync()
        const newRefs = references.map((ref) => {
          if (hashMap.has(ref.bindingId)) {
            const range = hashMap.get(ref.bindingId)
            if (!range) return ref
            const [, address] = range.address.split('!')
            return {
              ...ref,
              rangeAddr: address,
            }
          }
          return ref
        })
        const updatedUndos = undos.map((undo) => {
          const item = newRefs.find(
            (ref) => ref.bindingId === undo.reference.bindingId
          )
          if (item) {
            return {
              ...undo,
              reference: {
                ...item,
              },
            }
          }
          return undo
        })
        await dispatch(updateRefereces(newRefs))
        dispatch(updateUndoStack(updatedUndos))
      }),
    [dispatch, undos]
  )

  useEffect(() => {
    const deletionHandler = (sheetId: string, rangeAddress: string) =>
      Excel.run(async (ctx) => {
        try {
          const sheet = ctx.workbook.worksheets.getItemOrNullObject(sheetId)
          sheet.load(['isNullObject'])
          await ctx.sync()
          if (sheet.isNullObject) return
          const range = sheet.getRange(rangeAddress)
          const filtered = references.filter((ref) => ref.sheetId === sheetId)
          const filteredMap = new Map<string, TextcutRectangle>()
          filtered.forEach((ref) => filteredMap.set(ref.bindingId, ref))
          const intersectedMap = new Map<string, Excel.Range>()
          filtered.forEach((ref) => {
            const r = range.getIntersectionOrNullObject(ref.rangeAddr)
            r.load(['isNullObject'])
            intersectedMap.set(ref.bindingId, r)
          })
          await ctx.sync()
          const toBeDeleteIds: string[] = []
          for (const [k, v] of intersectedMap) {
            if (v.isNullObject) continue
            const item = filteredMap.get(k)
            if (!item) continue
            toBeDeleteIds.push(item.bindingId)
          }
          await dispatch(deleteReferencesWithBindingIds(toBeDeleteIds))
          const bindingMap = new Map<string, Excel.Binding>()
          for (const [k, v] of intersectedMap) {
            if (v.isNullObject) continue
            const bindingItem = ctx.workbook.bindings.getItemOrNullObject(k)
            bindingItem.load(['isNullObject'])
            bindingMap.set(k, bindingItem)
          }
          await ctx.sync().catch((err) => console.error(err))
          const releaseBindingQueue = []
          for (const [k, v] of bindingMap) {
            try {
              if (v.isNullObject) continue
              v.delete()
            } catch (err) {
              console.error('release binding:', k)
              releaseBindingQueue.push(k)
            }
          }
          for (const id of releaseBindingQueue) {
            await releaseBindingIdAsync(id).catch((err) => console.error(err))
          }

          await ctx.sync()
        } catch (err) {
          console.error(err)
        }
      })

    const handler = async (event: any) => {
      const e = event as unknown as CustomEvent
      const args: Excel.WorksheetChangedEventArgs = JSON.parse(e.detail)
      if (
        args.changeType === Excel.DataChangeType.cellInserted ||
        args.changeType === Excel.DataChangeType.columnInserted ||
        args.changeType === Excel.DataChangeType.rowInserted
      ) {
        await updateReferencesHelper(references).catch((err) =>
          console.log(err)
        )
      } else if (
        args.changeType === Excel.DataChangeType.cellDeleted ||
        args.changeType === Excel.DataChangeType.columnDeleted ||
        args.changeType === Excel.DataChangeType.rowDeleted
      ) {
        // const toBeDeleted = await removeInvalidBindings().catch((err) =>
        //   console.error(err)
        // )
        // if (toBeDeleted && toBeDeleted.length) {
        //   await dispatch(deleteReferencesWithBindingIds(toBeDeleted))
        // }
        // const filteredReferences = references.filter((ref) => {
        //   if (toBeDeleted && toBeDeleted.length) {
        //     return !toBeDeleted.some((id) => id === ref.bindingId)
        //   }
        //   return true
        // })
        // await updateReferencesHelper(filteredReferences).catch((err) =>
        //   console.error(err)
        // )
        await deletionHandler(args.worksheetId, args.address)
      } else if (args.changeType === 'RangeEdited') {
        await onRangeEdit(args.worksheetId, args.address, references).then(async (ids) => {
          if (ids) await dispatch(deleteReferencesWithBindingIds(ids))
        }).catch(err => console.error(`OnExcelWorkbooksRangeValueChange RangeEdited error={${err}}`))
      }
    }

    window.addEventListener('OnExcelWorkbooksRangeValueChange', handler)

    return () =>
      window.removeEventListener('OnExcelWorkbooksRangeValueChange', handler)
  }, [references, dispatch, updateReferencesHelper])
}

export default useOnChangedReceiver
