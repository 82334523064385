import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined'
import { MouseEventHandler, ReactNode } from 'react'

type Props = {
  setUpload: () => void
  createNewFolder: () => void
  showDownloadOptions: () => void
  showDeleteOptions: () => void
  showIncludeInExcel: () => void
  upload: boolean
  uploadOnProgress: boolean
  numberOfFiles: number
  allFilesProcessed: boolean
  setSelectMultipleFiles: React.Dispatch<React.SetStateAction<boolean>>
}

type CustomMenuItemProps = {
  disabled: boolean
  OnClick: MouseEventHandler<HTMLButtonElement> | undefined
  icon: JSX.Element
  text: ReactNode
}

const CustomMenuItem = ({
  disabled,
  icon,
  OnClick,
  text,
}: CustomMenuItemProps) => {
  return (
    <MenuItem disabled={disabled}>
      <button
        className={`flex gap-2 items-center justify-around  p-2 font-semibold ${
          disabled ? 'text-gray-400' : 'text-gray-800 hover:bg-gray-100'
        }`}
        onClick={OnClick}
      >
        <div>{icon}</div>
        <div className="flex-grow">{text}</div>
      </button>
    </MenuItem>
  )
}

const MobileHeader = ({
  createNewFolder,
  setUpload,
  showDeleteOptions,
  showDownloadOptions,
  showIncludeInExcel,
  upload,
  uploadOnProgress,
  numberOfFiles,
  allFilesProcessed,
  setSelectMultipleFiles,
}: Props) => {
  return (
    <div className="flex justify-center items-center sm:hidden">
      <Menu>
        <MenuButton className="mr-3 transition ease-in-out delay-75 hover:scale-105">
          <ExpandCircleDownOutlinedIcon sx={{ fontSize: 20 }} />
        </MenuButton>
        <MenuItems
          anchor="bottom start"
          className="z-50 flex flex-col bg-white w-48 shadow border-gray-200 rounded border gap-2"
        >
          <CustomMenuItem
            OnClick={setUpload}
            disabled={upload || uploadOnProgress}
            icon={<CloudUploadOutlinedIcon sx={{ fontSize: 20 }} />}
            text={'Upload files'}
          />

          <CustomMenuItem
            OnClick={() => setSelectMultipleFiles(true)}
            disabled={upload || uploadOnProgress}
            icon={<CheckBoxOutlinedIcon sx={{ fontSize: 20 }} />}
            text={'Select files'}
          />

          <CustomMenuItem
            OnClick={createNewFolder}
            disabled={upload || uploadOnProgress}
            icon={<CreateNewFolderOutlinedIcon sx={{ fontSize: 20 }} />}
            text={'Create folder'}
          />

          <CustomMenuItem
            OnClick={showIncludeInExcel}
            disabled={
              upload || uploadOnProgress || !allFilesProcessed || !numberOfFiles
            }
            icon={<CloudDownloadOutlinedIcon sx={{ fontSize: 20 }} />}
            text={'Include in Excel'}
          />

          <CustomMenuItem
            OnClick={showDownloadOptions}
            disabled={upload || !allFilesProcessed || !numberOfFiles}
            icon={<FileUploadOutlinedIcon sx={{ fontSize: 20 }} />}
            text={'Export options'}
          />

          <CustomMenuItem
            OnClick={showDeleteOptions}
            disabled={upload || !allFilesProcessed || !numberOfFiles}
            icon={<DeleteOutlinedIcon sx={{ fontSize: 20 }} />}
            text={'Delete options'}
          />
        </MenuItems>
      </Menu>
    </div>
  )
}

export default MobileHeader
