import { Button } from '@headlessui/react'
import { Dispatch, MouseEventHandler, ReactNode } from 'react'

type SearchModeBtnProps = {
  title: ReactNode
  content: ReactNode
  onClick: MouseEventHandler<HTMLButtonElement>
}

const SearchModeBtn = ({ content, onClick, title }: SearchModeBtnProps) => {
  return (
    <Button
      className={
        'flex flex-col gap-2 border p-2 items-start shadow hover:bg-gray-200 rounded-lg text-left'
      }
      onClick={onClick}
    >
      <div className="font-bold text-black text-lg">{title}</div>
      <div>{content}</div>
    </Button>
  )
}

type SearchModeProps = {
  setStepper: Dispatch<React.SetStateAction<0 | 1 | 2 | -1>>
  setInputRangeFocus: Dispatch<React.SetStateAction<boolean>>
  setSearchMode: Dispatch<React.SetStateAction<0 | 1 | 2>>
}

const SearchMode = ({
  setInputRangeFocus,
  setStepper,
  setSearchMode,
}: SearchModeProps) => {
  return (
    <div className="flex flex-col gap-2 px-2 pt-2">
      <SearchModeBtn
        content={'Select documents and/or folders. Generates a single output column for each input criteria.'}
        onClick={() => setSearchMode(1)}
        title={'General match'}
      />
      <SearchModeBtn
        content={
          'Select folders only. Generates separate outputs for each folder selected.'
        }
        onClick={() => setSearchMode(2)}
        title={'Sequential match'}
      />

      <div className="flex justify-end items-center">
        <button
          type="button"
          className="min-w-16  inline-flex m-1 text-teal-600 justify-center rounded-full border border-teal-500 px-2 py-1 sm:px-4 sm:py-2 text-xs sm:text-sm font-medium hover:bg-gray-100"
          onClick={() => {
            setStepper(0)
            setInputRangeFocus(true)
          }}
        >
          {'Prev'}
        </button>
      </div>
    </div>
  )
}

export default SearchMode
